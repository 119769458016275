import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';

interface TitleProps {
    children?: React.ReactNode;
    type?: any;
    className?: string;
    img?: string;
    text?: string;
}

const Title: FC<TitleProps> = ({ children, type, className, img, text }) => {
    const classes = useStyles();
    return (
        <Box className={`${classes.title} ${className}`}>
            {img ? <img alt="title icon" src={img} /> : null}
            <Typography variant={type}>
                {text} {children}
            </Typography>
        </Box >
    );
};

export default Title;
