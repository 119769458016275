import React, {FC} from 'react';

import Container from "../../../../../../components/Container";
import useStyles from "../styles";

const LowLatency: FC = () => {
  const classes = useStyles();

  return (
    <Container variant="large">
      <div className={classes.section}>
        <div className={classes.title}>
          <span className="lightRedBg">2</span>
          <div className="lightRed">POLAR Low-Latency (PLC) Codec</div>
        </div>
        <div className="rows">
          <div className="row row-1">
            <div className="caption lightRedBg">
              prototype development
            </div>
          </div>
          <div className="row row-2">
            <span className="mini-caption greyBg">Q1</span>
            <div className="text">Presentation of a working prototype</div>
          </div>
          <div className="row row-3">
            <span className="mini-caption greyBg mr4">Q1</span>
            <div className="text">Preparation for the commercial launch</div>
            <span className="mini-caption greyBg mr4">Q4</span>
            <span className="mini-caption lightRedBg">$$$</span>
            <div className="text">First revenue</div>
          </div>
          <div className="row row-4">
            <span className="mini-caption greyBg mr4">Q2</span>
            <span className="mini-caption lightRedBg">$$$</span>
            <div className="text">Market penetration</div>
          </div>
          <div className="row row-5">
            <span className="mini-caption lightRedBg">$$$</span>
            <div className="text">Expanding the target use in various industries</div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default LowLatency;
