import React, { FC } from 'react';

interface LoaderProps {
  load?: boolean
}

const Loader: FC<LoaderProps> = ({ load }) => {
  return (
    <div className={`loader ${load ? '' : "is-inview"}`}>
      <div className="loader-wrapper">
        <div className="loader-text2">
          <span data-scroll="" className={`${load ? '' : "is-inview"}`}>
            <span>LOADING
              <div className="dot-stretching"></div>
            </span>
          </span>
          <span data-scroll="" className={`${load ? '' : "is-inview"}`}>
            <span></span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Loader;