import React, { FC } from 'react';
import emailjs from 'emailjs-com';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button, Box, TextField } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import { SUCCESS, ERROR, showNotification } from 'redux/actions/notifications';
import NOTIFICATION from 'variables/notificationsList';

const validationSchema = yup.object({
  name: yup.string().required('Full Name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  message: yup
    .string()
    .min(12, 'Message should be of minimum 12 characters length')
    .required('Message is required'),
});

const ContactForm: FC = () => {
  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      await emailjs.send(
        'service_w1z1h7h',
        'template_f4a9zo3',
        values,
        'user_SHk8lldmBTejOrhUiMx3i',
      );
      showNotification(SUCCESS, NOTIFICATION.submitSuccesContacts);
      resetForm({});
    } catch (error) {
      showNotification(ERROR, NOTIFICATION.submitError);
      console.log(error);
    }
  };

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        message: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ errors, handleSubmit, handleChange, touched, values }) => (
        <Form onSubmit={handleSubmit}>
          <Box mb={1}>
            <TextField
              value={values.name}
              fullWidth
              label="Full Name*"
              name="name"
              onChange={handleChange}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
          </Box>
          <Box mb={1}>
            <TextField
              value={values.email}
              fullWidth
              label="E-mail*"
              name="email"
              onChange={handleChange}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
          </Box>
          <Box mb={5}>
            <TextField
              value={values.message}
              fullWidth
              label="Message"
              name="message"
              onChange={handleChange}
              error={touched.message && Boolean(errors.message)}
              helperText={touched.message && errors.message}
            />
          </Box>
          <Button color="primary" type="submit" endIcon={<ArrowForwardIcon />} variant="contained">
            Contact Us
          </Button>
        </Form>
      )}
    </Formik>
  );
};
export default ContactForm;
