import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: '0px',
    right: '0px',
    margin: '0 auto',
    maxWidth: '1333px',
    textAlign: 'center',
    background: 'transparent',
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: '19px 0px',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    minHeight: '54px',
  },
  nav:{
    flexGrow: 1,
    marginLeft: 46,
    display: "flex"
  },
  navList: {
    padding: '0px 10px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '0px',
      width: '100%',
      left: '0px',
      right: '0px',
      paddingTop: '100px',
      paddingBottom: '50px',
      backgroundColor: '#fff',
      boxShadow: '5px 5px 15px rgba(15, 36, 83, 0.07)',
    },

  },
  navLink: {
    color: theme.palette.common.white,
    transition: '0.4s',
    textDecoration: 'none',
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 500,
    position: 'relative',
    marginLeft: 24,
    '&:after': {
      content: "''",
      display: 'none',
      height: 1,
      background: '#fff',
    },
    '&:hover, &.active': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      margin: '0px',
      fontSize: '28px',
      lineHeight: '42px',
      marginBottom: '40px',
    },
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.only('lg')]: {
      width: "100%",
      justifyContent:"flex-end",
      marginRight: theme.spacing(2),
    },
  },
  swipeDrawer: {
    top: '100px!important',
    'z-index': '1099!important',
    '& .MuiBackdrop-root': {
      backgroundColor: '#ECF0FA!important',
      opacity: '0.6!important',
      zIndex: 1099,
    },
    '& .MuiPaper-root': {
      top: '54px',
    },
  },
}));

export default useStyles;
