import { makeStyles } from '@material-ui/core';
import  bg from '../../../../assets/images/Back.png'
const useStyles = makeStyles(theme => ({
    achievements: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "100% 100%",
        backgroundImage: `url(${bg})`,
        [theme.breakpoints.down('sm')]: {
            backgroundPosition: "100%",
            backgroundSize: "cover"
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            paddingTop: 78,
            paddingBottom: 88,
        },
        [theme.breakpoints.only('md')]: {
            paddingTop: 147,
            paddingBottom: 120,
            backgroundPosition: "center center",
            backgroundSize: "cover"
        },
        [theme.breakpoints.only('lg')]: {
            paddingTop: 80,
            paddingBottom: 50,
        },
        [theme.breakpoints.up('lg')]: {
            backgroundPosition: "100% 50%"
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: 130,
            paddingBottom: 23,
            backgroundPosition: "100% 50%"
        },
    },
    title: {
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.only('md')]: {
            marginBottom: theme.spacing(5),
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: theme.spacing(8),
            flexDirection: "row!important"
        },
    },
    x: {
        [theme.breakpoints.down('sm')]: {
            height: 562
        },
        [theme.breakpoints.only('md')]: {
            height: 565
        }
    },
    panelBox: {
        display: "flex",
        paddingBottom: 10,
        [theme.breakpoints.down('md')]: {
            overflowX: 'scroll',
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            }
        },
        [theme.breakpoints.up('lg')]: {
            flexFlow: 'wrap',
            justifyContent: 'space-between',
        },
        '&> div': {
            width: 288,
            flexShrink: 0,
            height: 'auto',
            marginRight: theme.spacing(1),
            '&:last-child': {
                marginRight: 0,
            },
            [theme.breakpoints.up('lg')]: {
                marginBottom: theme.spacing(6.875),
                width: 300,
                '&:nth-child(3)': {
                    marginRight: 0,
                },
            },
            [theme.breakpoints.up('xl')]: {
                width: 300,
            },
        },
    },
}));

export default useStyles;
