import React, { FC, useState } from 'react';
import {
  Typography,
  Box,
  Chip,
  IconButton,
  useMediaQuery,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import useHorizontalScroll from '../../../../hooks/useHorizontalScroll';
import DataTable from '../../../../components/DataTable';
import Container from '../../../../components/Container';
import Title from '../../../../components/Title';
import VideoJS from '../../../../components/Video';
import apple from '../../../../assets/images/icons/apple.svg';
import titleImg from '../../../../assets/images/icons/fire.svg';
import play from '../../../../assets/images/icons/play.svg';
import preview1 from '../../../../assets/images/preview1.png';
import preview2 from '../../../../assets/images/preview2.png';
import preview3 from '../../../../assets/images/preview3.png';
import useStyles from './styles';
import CustomTabs from '../../../../components/CustomTabs';
import ProductionTable from './ProductionTable';
import firstTableData from './firstTableData.json';
import secondTableData from './secondTableData.json';

const VIDEO_DATA = ['Polar H.mp4', 'Polar LM.mp4', 'Polar L.mp4'];
const COL_DATA = [
  {
    id: 0,
    chip: '1248x704',
    img: preview3,
  },
  {
    id: 1,
    chip: '5120x2560',
    img: preview2,
  },
  {
    id: 2,
    chip: '6144x2592',
    img: preview1,
  },
];
const TABLE_DATA = [
  [
    {
      '': '422HQ',
      't/sec': 67,
      's/Mb': 455,
      PSNR: 45,
    },
    {
      '': '422LT',
      't/sec': 214,
      's/Mb': 542,
      PSNR: 44.4,
    },
    {
      '': '422',
      't/sec': 666,
      's/Mb': 2553,
      PSNR: 45,
    },
  ],
  [
    {
      '': 'H',
      't/sec': 23,
      's/Mb': 376,
      PSNR: 50,
    },
    {
      '': 'LM',
      't/sec': 155,
      's/Mb': 267,
      PSNR: 40.7,
    },
    {
      '': 'L',
      't/sec': 525,
      's/Mb': 1189,
      PSNR: 46,
    },
  ],
];

const TABS = {
  0: [
    {
      name: 'Production',
      subtitle: 'Codec',
    },
    {
      name: 'Low-latency (PLC)',
      subtitle: 'Codec',
    },
  ],
  1: [
    {
      name: 'Actual videos',
    },
    {
      name: 'Synthetic Image',
    },
  ],
};

const Production: FC = () => {
  const classes = useStyles();
  const scrollRef = useHorizontalScroll<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(0);
  const [tab1Value, setTab1Value] = useState({ tabIndex: 1, variant: 0 });
  const [tab2Value, setTab2Value] = useState({ tabIndex: 0, variant: 1 });

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleClose = (): void => setOpen(false);

  const handlePalyVideo = (number): void => {
    setOpen(true);
    setCurrentVideo(number);
  };

  return (
    <section className={classes.production} id="production">
      <Container variant="default">
        <Title className={classes.title} img={titleImg} type="h2">
          Apple ProRes <br />
          vs<Box component="span"> POLAR </Box>Production Codec
        </Title>
      </Container>
      <Container variant="large">
        <div className={`${classes.tabs} ${tab1Value.tabIndex === 1 && classes.hideBorderBottom}`}>
          <CustomTabs
            isFocused={tab1Value.variant === 0}
            onChange={index => setTab1Value({ tabIndex: index, variant: 0 })}
            tabs={TABS[0]}
            title="Light Algorithm"
            value={tab1Value.tabIndex}
          />
        </div>
      </Container>

      <div className={classes.tabContent}>
        {tab1Value.tabIndex === 0 ? (
          <>
            <Container variant="large">
              <Box display="flex" mb={8} position="relative">
                <div className={classes.column} style={{ marginBottom: '80px' }}>
                  <Box fontSize={15} mb={1} textAlign="center">
                    SIZE WxHxC
                  </Box>
                  {COL_DATA.map(item => (
                    <div
                      key={item.id}
                      className={classes.item}
                      onClick={() => handlePalyVideo(item.id)}
                    >
                      <Chip className={classes.chip} label={item.chip} size="small" />
                      <img alt="preview" className={classes.preview} src={item.img} />
                      <IconButton className={classes.play}>
                        <img alt="play" src={play} />
                      </IconButton>
                    </div>
                  ))}
                </div>
                <div
                  className={classes.content}
                  style={tab1Value.tabIndex === 0 ? { height: '560px' } : {}}
                >
                  <div ref={scrollRef} className={classes.box}>
                    <div className={classes.pointerEventsNone}>
                      <DataTable data={TABLE_DATA[0]} icon={apple} title="ProRes" type="default" />
                    </div>
                    <div className={classes.secondTable}>
                      <div className={classes.pointerEventsNone}>
                        <DataTable data={TABLE_DATA[1]} title="Polar" type="primary" />
                      </div>
                      <div className={`${classes.btnGroup}`}>
                        <Button
                          color="primary"
                          download
                          endIcon={<ArrowForwardIcon />}
                          fullWidth
                          href="docs/PLC_vs_ProRes.pdf"
                          size="small"
                          variant="contained"
                        >
                          Download the pdf
                        </Button>
                        <div className={classes.btnDesc} style={{ width: 540 }}>
                          to get the full comparison
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
              <div className={classes.p}>
                <div className={classes.a}>
                  <Chip label="t/sec" size="small" />
                  <div className={classes.c}>
                    <div>encoding time in seconds</div>
                    <Typography color="primary" component="span">
                      lower&nbsp;
                    </Typography>
                    <Typography component="span">is better </Typography>
                  </div>
                </div>
                <div className={classes.a}>
                  <Chip label="s/Mb" size="small" />
                  <div className={classes.c}>
                    <div>the size of the encoding time in Megabytes</div>
                    <Typography color="primary" component="span">
                      lower&nbsp;
                    </Typography>
                    <Typography component="span">is better</Typography>
                  </div>
                </div>
                <div className={classes.a}>
                  <Chip label="PSNR" size="small" />
                  <div className={classes.c}>
                    <div>Peak signal-to-noise ratio</div>
                    <Typography color="primary" component="span">
                      higher&nbsp;
                    </Typography>
                    <Typography component="span">is better</Typography>
                  </div>
                </div>
              </div>
            </Container>
            <Dialog
              className={classes.modal}
              fullScreen={fullScreen}
              maxWidth="lg"
              onClose={handleClose}
              open={open}
            >
              <DialogContent>
                <VideoJS
                  options={{
                    autoplay: true,
                    controls: true,
                    responsive: true,
                    fluid: true,
                    width: '100%',
                    height: '100%',
                    sources: [
                      {
                        src: VIDEO_DATA[currentVideo],
                        type: 'video/mp4',
                      },
                    ],
                  }}
                />
              </DialogContent>
              <DialogActions disableSpacing>
                <Button autoFocus color="primary" onClick={handleClose}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <Container variant="large">
            <div>
              <div className={`${classes.tabs} ${classes.hideBorderBottom} ${classes.customTab}`}>
                <div
                  className={`${classes.subTab} ${tab2Value.tabIndex === 0 && classes.selectedTab}`}
                  onClick={() => setTab2Value({ tabIndex: 0, variant: 1 })}
                >
                  {TABS[1][0].name}
                </div>
                <div
                  className={`${classes.subTab} ${tab2Value.tabIndex === 1 && classes.selectedTab}`}
                  onClick={() => setTab2Value({ tabIndex: 1, variant: 1 })}
                >
                  {TABS[1][1].name}
                </div>
              </div>
              <div
                className={`${classes.content} ${classes.customContent}
                 ${classes.pointerEventsNone} ${
            tab2Value.tabIndex === 1 ? classes.tableIndex1 : ''
          }`}
              >
                <div ref={scrollRef} className={classes.customBox}>
                  <div>
                    {tab2Value.tabIndex === 0 ? (
                      <ProductionTable tableData={firstTableData} tableIndex={tab2Value.tabIndex} />
                    ) : (
                      <ProductionTable
                        tableData={secondTableData}
                        tableIndex={tab2Value.tabIndex}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.btnGroup}>
                <Button
                  color="primary"
                  download
                  endIcon={<ArrowForwardIcon />}
                  fullWidth
                  href="docs/PLC_vs_H.264.pdf"
                  size="small"
                  variant="contained"
                >
                  Download the pdf
                </Button>
                <div className={classes.btnDesc}>to get the full comparison</div>
              </div>
            </div>

            <div className={classes.p2}>
              <div className={classes.a}>
                <Chip label="t/sec" size="small" />
                <div className={classes.c}>
                  <div>encoding time in seconds</div>
                  <Typography color="primary" component="span">
                    lower&nbsp;
                  </Typography>
                  <Typography component="span">is better </Typography>
                </div>
              </div>
              <div className={classes.a}>
                <Chip label="s/Mb" size="small" />
                <div className={classes.c}>
                  <div>the size of the encoding time in Megabytes</div>
                  <Typography color="primary" component="span">
                    lower&nbsp;
                  </Typography>
                  <Typography component="span">is better</Typography>
                </div>
              </div>
              <div className={classes.a}>
                <Chip label="PSNR" size="small" />
                <div className={classes.c}>
                  <div>Peak signal-to-noise ratio</div>
                  <Typography color="primary" component="span">
                    higher&nbsp;
                  </Typography>
                  <Typography component="span">is better</Typography>
                </div>
              </div>
              {tab2Value.tabIndex === 0 && (
                <>
                  <div className={classes.a}>
                    <Chip label="Mb/s for 30fps" size="small" />
                    <div className={classes.c}>
                      <div>speed of stream for video coding</div>
                      <Typography color="primary" component="span">
                        lower&nbsp;
                      </Typography>
                      <Typography component="span">is better </Typography>
                    </div>
                  </div>
                  <div className={classes.a}>
                    <Chip label="Lframe (ms)" size="small" />
                    <div className={classes.c}>
                      <div>latency for computing frame</div>
                      <Typography color="primary" component="span">
                        lower&nbsp;
                      </Typography>
                      <Typography component="span">is better </Typography>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Container>
        )}
      </div>
    </section>
  );
};
export default Production;
