import { combineReducers } from 'redux';
import modals from './modals';
import application from './application';

const rootReducer = combineReducers({
  modals,
  application,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
