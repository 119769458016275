import React, { FC, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  Hidden,
  Grid,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import NavigationDrawer from './NavigationDrower';

import logo from '../../assets/images/logo.png';
import useStyles from './styles';

const menuItems = [
  {
    link: '#solutions',
    name: 'Our Solutions',
  },
  {
    link: '#achievements',
    name: 'Our Achievements',
  },
  {
    link: '#poc',
    name: 'Proof of Concept',
  },
  {
    link: '#comparison',
    name: 'Comparison',
  },
];

const Header: FC = () => {
  const classes = useStyles();
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const handleMobileDrawerOpen = useCallback(() => {
    setIsMobileDrawerOpen(true);
  }, [setIsMobileDrawerOpen]);

  const handleMobileDrawerClose = useCallback(() => {
    setIsMobileDrawerOpen(false);
  }, [setIsMobileDrawerOpen]);

  return (
    <>
      <AppBar className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Link to="/">
            <img alt="logo" src={logo} />
          </Link>
          <Hidden lgDown>
            <div className={classes.nav}>
              {menuItems.map(element => (
                <a key={element.name} className={classes.navLink} href={element.link}>
                  {element.name}
                </a>
              ))}
            </div>
          </Hidden>
          <Hidden mdDown>
            <Box className={classes.controls}>
              <Box mr={2}>
                <Button
                  download
                  color="secondary"
                  fullWidth
                  variant="text"
                  size="small"
                  href="docs/Polar-presentation.pdf"
                >
                  Download Presentation
                </Button>
              </Box>

              <Box width={139}>
                <Button color="secondary" fullWidth size="small" variant="contained" href="#footer">
                  Contact Us
                </Button>
              </Box>
            </Box>
          </Hidden>
          <Hidden xlUp>
            <IconButton color="secondary" onClick={handleMobileDrawerOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      <NavigationDrawer
        anchor="top"
        menuItems={menuItems}
        onClose={handleMobileDrawerClose}
        open={isMobileDrawerOpen}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Button
              download
              fullWidth
              color="secondary"
              variant="text"
              href="docs/Polar-presentation.pdf"
            >
              Download Presentation
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button href="#footer" fullWidth color="secondary" variant="contained">
              Contact Us
            </Button>
          </Grid>
        </Grid>
      </NavigationDrawer>
    </>
  );
};

export default Header;
