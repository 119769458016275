import React, {FC} from 'react';
import {Box} from "@material-ui/core";

import Title from "../../../../components/Title";
import pulseImg from "../../../../assets/images/icons/pulse.svg";
import Container from "../../../../components/Container";
import useHorizontalScroll from "../../../../hooks/useHorizontalScroll";

import Production from './sections/production';
import LowLatency from "./sections/lowLatency";
import Streaming from "./sections/streaming";
import Texture from "./sections/texture";
import Recognition from './sections/recognition';
import Timeline from './sections/timeline';
import useStyles from "./styles";

const Plans: FC = () => {
  const classes = useStyles();
  const scrollRef = useHorizontalScroll<HTMLDivElement>();


  return (
    <section className={classes.plans}>
      <Container variant="default">
        <Title className={classes.title} img={pulseImg} type="h2">
          Our<Box component="span"> Accomplishments</Box><br/>
          <Box component="span">and Future Plans</Box>
        </Title>
      </Container>
      <div className={classes.sections}>
        <div ref={scrollRef}>
          <Production/>
          <LowLatency/>
          <Streaming/>
          <Texture/>
          <Recognition/>
          <Timeline/>
        </div>
      </div>
    </section>
  )
}

export default Plans;
