import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducers from './redux/reducers';

const { NODE_ENV } = process.env;

const composeEnhancers =
  (NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)));

export type AppState = ReturnType<typeof reducers>;
export default store;
