import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  team: {
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: '114px',
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: '216px',
    },
    [theme.breakpoints.only('lg')]: {
      paddingTop: 130,
      paddingBottom: 133,
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: 260,
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(5),
      display: 'flex'
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(5.875),
    },
    [theme.breakpoints.only('lg')]: {
      flexDirection: 'row!important',
      marginBottom: 29,
      '&>img': {
        marginBottom: '0!important',
      },
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(7.375),
    },
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center',
      '& > div': {
        marginRight: 0
      }
    },
  }
}));

export default useStyles;
