import React, { FC } from 'react';
import {
  Typography,
  Paper,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import useStyles from './styles';

interface DataTableProps {
  data?: any;
  title?: string;
  icon?: string;
  className?: any;
  type?: string;
}

const DataTable: FC<DataTableProps> = ({ data, title, icon, className, type }) => {
  const classes = useStyles();
  const headers = Object.keys(data[0]);
  return (
    <Paper className={`${classes.paper} ${className}`}>
      <div className={`${classes.paperHead} ${type ? type : ''}`}>
        {icon ? <img alt="apple" src={icon} /> : null}
        <Typography variant="h3">{title}</Typography>
      </div>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index} className={classes.tableCellHead} align="left">
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {headers.map((col, index) => (
                <TableCell key={index} align="left" className={classes.tableCell}>
                  {index === 0 ? <Chip className={classes.chips} label={row[col]} /> : row[col]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default DataTable;
