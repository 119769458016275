import { makeStyles } from '@material-ui/core';
import imgBg from '../../../../assets/images/POLAR_PromoPage_Illustration.svg';
const useStyles = makeStyles(theme => ({
  banner: {
    overflow: "hidden"
  },
  wrapper: {
    position: "relative",
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: 93,
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: 132,
      paddingBottom: 100,
    },
    [theme.breakpoints.only('lg')]: {
      paddingTop: 178,
      paddingBottom: 152,
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: 154,
      paddingBottom: 78,
    },
    "&:before": {
      content: ' "" ',
      display: "block",
      position: "absolute",
      width: 800,
      height: 800,
      zIndex: 0,
      [theme.breakpoints.up('md')]: {
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${imgBg})`,
      },
      [theme.breakpoints.only('md')]: {
        right: -335
      },
      [theme.breakpoints.only('lg')]: {
        width: 927,
        height: 500,
        right: -266
      },
      [theme.breakpoints.up('xl')]: {
        top: 267,
        right: -600,
        width: 1300,
        height: 1600,
      }
    }
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: 510,
      marginBottom: theme.spacing(3.625),
      fontSize: 56
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(4),
    },
    '&> div': {
      background: 'linear-gradient(269.9deg, #3C39FB 1.82%, #66A5FE 35.06%, #24D1F3 53.98%, #27EDBE 72.9%, #2AFD9B 100%);',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    }
  },
  subtitle: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
      paddingRight: 1,
      paddingTop: 4,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      marginBottom: theme.spacing(3),
      maxWidth: 377,
      fontSize: 17
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(6),
      maxWidth: 604,
    },
  },
  btnDown: {
    "&:hover": {
      backgroundColor: "transparent",
      '& .MuiButton-startIcon': {
        border: '1px solid #29F4B2',
      }
    },
    '& .MuiButton-startIcon': {
      border: '1px solid #29f4b233',
      borderRadius: '50%',
      fontSize: 24,
      width: 40,
      height: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "0.4s",
      marginRight: 10
    },
  },
}));
export default useStyles;