import React, { FC } from 'react';
import { Typography, Button, Hidden, Box } from '@material-ui/core';
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowForward as ArrowForwardIcon,
} from '@material-ui/icons';
import Container from '../../../../components/Container';
import useStyles from './styles';

const Banner: FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.banner} id="banner">
      <Container variant="default">
        <section className={classes.wrapper}>
          <Typography className={classes.title} variant="h1">
            <div>Streaming</div> a Better Tomorrow. Together.
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle1">
            Using proprietary technology and a distinctive approach to create a next generation
            multimedia codec family
          </Typography>
          <Box display={{ xl: 'flex' }} width={{ md: 288, xl: '100%!important' }} mb={5}>
            <Box mb={3} width={{ xl: 172 }} order={{ xl: 2 }}>
              <Button
                color="primary"
                href="#footer"
                endIcon={<ArrowForwardIcon />}
                fullWidth
                variant="contained"
              >
                Contact Us
              </Button>
            </Box>
            <Box mb={3} width={{ xl: 223 }} mr={{ xl: 3 }} order={{ xl: 1 }}>
              <Button
                download
                color="secondary"
                href="docs/Polar-presentation.pdf"
                fullWidth
                variant="outlined"
              >
                Download Presentation
              </Button>
            </Box>
          </Box>
          <Hidden lgDown>
            <Button
              className={classes.btnDown}
              color="secondary"
              startIcon={<ArrowDownwardIcon color="primary" />}
              variant="text"
              href="#codec"
            >
              Scroll Down
            </Button>
          </Hidden>
        </section>
      </Container>
    </section>
  );
};

export default Banner;
