import React, { FC } from 'react';
import { Box } from '@material-ui/core';

interface TabPanelProps {
	className?: any;
	children?: React.ReactNode;
	index: string | number;
	value: string | number;
}

const TabPanel: FC<TabPanelProps> = ({ children, className, value, index, ...other }) => {
	return (
		<div
			className={className}
			aria-labelledby={`wrapped-tab-${index}`}
			hidden={value !== index}
			id={`wrapped-tabpanel-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
};

export default TabPanel;
