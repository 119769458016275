import {
  SET_ENTRIES,
  AplicationActionTypes,
} from '../types/actionTypes';
import { ApplicationState } from '../types';

const initialState: ApplicationState = {
  entries: [],
};

const application = (state = initialState, action: AplicationActionTypes): ApplicationState => {
  switch (action.type) {
    case SET_ENTRIES:
      return {
        ...state,
        entries: action.payload,
      };
    default:
      return state;
  }
};

export default application;
