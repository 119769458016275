import { ModalState } from '../types';
import { AplicationActionTypes, OPEN_MODAL, CLOSE_MODAL } from '../types/actionTypes';

const initialState: ModalState = {
  modalType: null,
  data: null,
};

const modals = (state = initialState, action: AplicationActionTypes): ModalState => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        modalType: action.payload.type,
        data: action.payload.data,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modalType: null,
        data: null,
      };
    default:
      return state;
  }
};

export default modals;
