/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { useTable, useGroupBy, useExpanded } from 'react-table';

function Table({ columns, data }): JSX.Element {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useGroupBy,
    useExpanded,
  );

  const firstPageRows = rows.slice(0, 4);

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => {
          return (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, index) => {
                return (
                  <th {...column.getHeaderProps()}
                    key={index}
                    className={column.id === 'row1_0' ? 'row1_0' : ''}
                  >
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {firstPageRows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={i}>
              {row.cells.map((cell, i) => {
                return <td {...cell.getCellProps()} key={i}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
export default Table;
