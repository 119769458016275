import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  codec: {
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: 105,
      paddingBottom: 75,
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: 120,
      paddingBottom: 120,
    },
    [theme.breakpoints.only('lg')]: {
      paddingTop: 130,
      paddingBottom: 35,
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: 130,
      paddingBottom: 42,
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(6),
    },
  },
  subtitle: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1.4),
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(5.5),
      maxWidth: 583,
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(7),
      maxWidth: 630,
    },
  },
  tabs: {
    marginBottom: theme.spacing(4.75),
    borderBottom: '1px solid #ffffff4d',
    '& .MuiButtonBase-root': {
      color: '#fff',
      textTransform: 'none',
      fontWeight: 600,
      lineHeight: 1.5,
      flexGrow: 1,
      '&:hover': {
        color: '#29F4B2',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '23px 12px 10px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '12px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '24px',
        padding: '21px 10px',
      },
      '&.Mui-selected': {
        color: '#29F4B2',
      },
    },
  },
  content: {
    [theme.breakpoints.down('md')]: {
      padding: '10px 0px',
    },
    [theme.breakpoints.only('lg')]: {
      padding: '50px 1px 10px'
    },
    [theme.breakpoints.up('xl')]: {
      padding: '95px 5px 5px!important',
    },
    '& h3': {
      lineHeight: 1.5,
      marginBottom: theme.spacing(2),
    },
  },
  preview: {
    textAlign: "center",
    [theme.breakpoints.only('md')]: {
      height: 268,
    },
    [theme.breakpoints.only('lg')]: {
      height: 369,
    },
    [theme.breakpoints.up('xl')]: {
      height: 520,
    },
    '& img': {
      maxWidth: '100%',
    },
  },
}));

export default useStyles;
