import {makeStyles} from '@material-ui/core';
import bg from "../../../../assets/images/Back.png";

const useStyles = makeStyles(theme => ({
  plans: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: "100% 100%",
    backgroundImage: `url(${bg})`,
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: "100%",
      backgroundSize: "cover"
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: 78,
      paddingBottom: 88,
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: 147,
      paddingBottom: 120,
      backgroundPosition: "center center",
      backgroundSize: "cover"
    },
    [theme.breakpoints.only('lg')]: {
      paddingTop: 80,
      paddingBottom: 50,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundPosition: "100% 50%"
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: 130,
      paddingBottom: 23,
      backgroundPosition: "100% 50%"
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: '114px',
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: '216px',
    },
    [theme.breakpoints.only('lg')]: {
      paddingTop: 130,
      paddingBottom: 133,
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: 260,
    },
  },
  title: {
    '& h2': {
      '@media (max-width: 380px)': {
        fontSize: '35px !important',
      }
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(5.875),
    },
    [theme.breakpoints.only('lg')]: {
      flexDirection: 'row!important',
      marginBottom: 29,
      '&>img': {
        marginBottom: '0!important',
      },
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(7.375),
    },
  },
  sections: {
    overflow: 'hidden',
    '& > div': {
      overflowX: 'scroll',
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      '@media (min-width: 1070px)': {
        pointerEvents: 'none',
      },
    },
    '& > div::-webkit-scrollbar': {
      display: 'none'
    }
  }
}));

export default useStyles;
