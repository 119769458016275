import MikiPhoto from '../../../../assets/images/team/miki.png';
import DanielPhoto from '../../../../assets/images/team/daniel.png';
import GeorgePhoto from '../../../../assets/images/team/george.png';
import SergeyPhoto from '../../../../assets/images/team/sergey.png';
import ViktorPhoto from '../../../../assets/images/team/viktor.png';
import PeterPhoto from '../../../../assets/images/team/peter.png';
import IgorPhoto from '../../../../assets/images/team/igor.png';

export const headTeamList = [
  {
    firstName: 'Miki',
    lastName: 'Granski',
    photo: MikiPhoto,
    profession: 'Chairman',
    linkedIn: 'https://www.linkedin.com/in/mgranski/',
  },
  {
    firstName: 'Daniel',
    lastName: 'Korogodski',
    photo: DanielPhoto,
    profession: 'CEO, co-founder',
    linkedIn: 'https://www.linkedin.com/in/fbchief/',
  },
  {
    firstName: 'George',
    lastName: 'Dzyncharadze',
    photo: GeorgePhoto,
    profession: 'COO, co-founder',
    linkedIn: 'https://www.linkedin.com/in/george-dzhincharadze-7b8269149/',
  },
];

export const teamList = [
  {
    firstName: 'Sergey',
    lastName: 'Rokhvarg',
    photo: SergeyPhoto,
    profession: 'CTO, DIRECTOR',
    linkedIn: 'https://www.linkedin.com/in/sergeyrokhvarg-3718b543/',
  },
  {
    firstName: 'Viktor',
    lastName: 'Konovalov',
    photo: ViktorPhoto,
    profession: 'CMO, director',
    linkedIn: 'https://www.linkedin.com/in/konovalovxclv/',
  },
  {
    firstName: 'Peter',
    lastName: 'Wielgosz',
    photo: PeterPhoto,
    profession: 'General counsel',
    linkedIn: 'https://www.linkedin.com/in/peterwielgosz/',
  },
  {
    firstName: 'Igor',
    lastName: 'Kovryga',
    photo: IgorPhoto,
    profession: 'CHO & CHIP Architect',
    linkedIn: 'https://www.linkedin.com/in/kovryga-igor-76671211a/',
  },
];
