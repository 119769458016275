import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import Home from './containers/Home';
import './assets/styles/index.scss';

const App: FC = () => {
  return (
    <>
      <ReactNotification />
      <Switch>
        <Route component={Home} exact path="/" />
      </Switch>
    </>
  );
};

export default App;
