import React, { FC, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  List,
  ListItem,
  Drawer,
  IconButton,
  Typography,
  isWidthUp,
  makeStyles,
  Box,
  Toolbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import logo from '../../assets/images/logo.png';
import Image from '../../assets/images/background-variant-1.svg';

interface DrowerProps {
  anchor?: any;
  theme?: string;
  open?: boolean;
  onClose?: any;
  menuItems?: any;
  className?: string;
  width?: any;
  selectedItem?: string;
  children?: any;
}

const useStyles = makeStyles(theme => ({
  drower: {
    '&> .MuiDrawer-paper': {
      height: '100%',
      backgroundImage: `url(${Image}), linear-gradient(251.58deg, #321958 -40.91%, #1B1B23 28.08%, #100D17 100%)`,
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
    },
  },
  navLink: {
    color: '#fff',
    textDecoration: 'none',
    width: '100%',
  },
  closeIcon: {
    marginRight: theme.spacing(0.5),
  },
  dropNav: {
    height: '100%',
    '&> .MuiListItem-root': {
      borderBottom: '1px solid #fff',
      marginBottom: '15px',
    },
  },
}));

const NavigationDrawer: FC<DrowerProps> = ({
  width,
  open,
  onClose,
  anchor,
  className,
  menuItems,
  selectedItem,
  children,
}) => {
  const classes = useStyles();
  useEffect(() => {
    window.onresize = () => {
      if (isWidthUp('sm', width) && open) {
        onClose();
      }
    };
  }, [width, open, onClose]);

  return (
    <Drawer
      anchor={anchor}
      className={`${classes.drower} ${className}`}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <Toolbar>
        <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
          <Link to="/">
            <img alt="logo" src={logo} />
          </Link>
          <IconButton color="secondary" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="space-Between"
        px={2}
        py={3}
      >
        <List className={classes.dropNav}>
          {menuItems.map(element => (
            <ListItem
              key={element.name}
              button
              disableGutters
              disableRipple
              disableTouchRipple
              selected={selectedItem === element.name}
            >
              <a
                key={element.name}
                className={classes.navLink}
                onClick={onClose}
                href={element.link}
              >
                <Typography variant="subtitle1">{element.name}</Typography>
              </a>
            </ListItem>
          ))}
        </List>
        {children}
      </Box>
    </Drawer>
  );
};

export default NavigationDrawer;
