import React, { FC, useState } from 'react';
import { Typography } from '@material-ui/core';

import Title from '../../../../components/Title';
import Container from '../../../../components/Container';
import TabPanel from '../../../../components/TabPanel';
import titleImg from '../../../../assets/images/icons/globe.svg';
import { ReactComponent as FRAME_1_1 } from '../../../../assets/images/frame-1-1.svg';
import { ReactComponent as FRAME_1_2 } from '../../../../assets/images/frame-1-2.svg';
import { ReactComponent as FRAME_1_3 } from '../../../../assets/images/frame-1-3.svg';
import { ReactComponent as FRAME_1_4 } from '../../../../assets/images/frame-1-4.svg';
import { ReactComponent as FRAME_2_1 } from '../../../../assets/images/frame-2-1.svg';
import { ReactComponent as FRAME_2_2 } from '../../../../assets/images/frame-2-2.svg';
import { ReactComponent as FRAME_2_3 } from '../../../../assets/images/frame-2-3.svg';
import { ReactComponent as FRAME_2_4 } from '../../../../assets/images/frame-2-4.svg';
import { ReactComponent as FRAME_3_1 } from '../../../../assets/images/frame-3-1.svg';
import { ReactComponent as FRAME_3_2 } from '../../../../assets/images/frame-3-2.svg';
import { ReactComponent as FRAME_3_3 } from '../../../../assets/images/frame-3-3.svg';
import { ReactComponent as FRAME_3_4 } from '../../../../assets/images/frame-3-4.svg';
import { ReactComponent as FRAME_4_1 } from '../../../../assets/images/frame-4-1.svg';
import { ReactComponent as FRAME_4_2 } from '../../../../assets/images/frame-4-2.svg';
import { ReactComponent as FRAME_4_3 } from '../../../../assets/images/frame-4-3.svg';
import { ReactComponent as FRAME_4_4 } from '../../../../assets/images/frame-4-4.svg';
import { ReactComponent as FRAME_5_1 } from '../../../../assets/images/frame-5-1.svg';
import { ReactComponent as FRAME_5_2 } from '../../../../assets/images/frame-5-2.svg';
import { ReactComponent as FRAME_5_3 } from '../../../../assets/images/frame-5-3.svg';
import { ReactComponent as FRAME_5_4 } from '../../../../assets/images/frame-5-4.svg';

import useStyles from './styles';
import CustomTabs from '../../../../components/CustomTabs';

const TABS = {
  0: [
    {
      name: 'Production',
      subtitle: 'Codec',
      frame: [
        {
          name: 'Faster Encoding',
          img: <FRAME_1_1 />,
        },
        {
          name: 'Faster Editing',
          img: <FRAME_1_2 />,
        },
        {
          name: 'More Parallel Streams',
          img: <FRAME_1_3 />,
        },
        {
          name: 'Higher Compression',
          img: <FRAME_1_4 />,
        },
      ],
    },
    {
      name: 'Low-latency (PLC)',
      subtitle: 'Codec',
      frame: [
        {
          name: 'Lower Latency for Data Transfer',
          img: <FRAME_2_1 />,
        },
        {
          name: 'Lower Power Consumption',
          img: <FRAME_2_4 />,
        },
        {
          name: 'AR/VR Compatible',
          img: <FRAME_2_2 />,
        },
        {
          name: 'Less Space on Chip',
          img: <FRAME_2_3 />,
        },
      ],
    },
    {
      name: 'Object Recognition System',
      frame: [
        {
          name: 'Fast Object Recognition',
          img: <FRAME_3_1 />,
        },
        {
          name: 'Less Demanding on Hardware',
          img: <FRAME_3_2 />,
        },
        {
          name: 'Motion Tracking',
          img: <FRAME_3_3 />,
        },
        {
          name: 'On Camera Object Recognition',
          img: <FRAME_3_4 />,
        },
      ],
    },
  ],
  1: [
    {
      name: 'Streaming',
      subtitle: 'Codec',
      frame: [
        {
          name: 'High Quality Live Content in Rural Areas',
          img: <FRAME_4_1 />,
        },
        {
          name: 'Longer Battery Life via Light Decoding',
          img: <FRAME_4_2 />,
        },
        {
          name: '8k Content on 4k Infrastructure',
          img: <FRAME_4_3 />,
        },
        {
          name: 'Lower CDN Bandwidth Consumption',
          img: <FRAME_4_4 />,
        },
      ],
    },
    {
      name: 'Texture',
      subtitle: 'Codec',
      frame: [
        {
          name: 'Improved GPU Performance',
          img: <FRAME_5_1 />,
        },
        {
          name: 'Lower Latency in Games',
          img: <FRAME_5_2 />,
        },
        {
          name: 'Versatile Block Size ',
          img: <FRAME_5_3 />,
        },
        {
          name: 'Lower Power Consumption',
          img: <FRAME_5_4 />,
        },
      ],
    },
  ],
};

const className = {
  0: 'light',
  1: 'complex',
};

const Solutions: FC = () => {
  const classes = useStyles();
  const [value, setValue] = useState({ tabIndex: 1, variant: 0 });

  return (
    <section className={classes.solutions} id="solutions">
      <Container variant="default">
        <Title className={classes.title} img={titleImg} text="POLAR’s Solutions" type="h1" />
        <Typography className={classes.subtitle} variant="subtitle1">
          Over the last two years, POLAR’s team of mathematicians and developers have been working
          on creating the technological foundation upon which POLAR will build its codec family.
          This foundation uses a proprietary mathematical approach to obtain both higher flexibility
          in terms of technological capabilities and a more robust IP protection strategy and have
          led to the creation of two algorithms: the POLAR Light Algorithm and the POLAR Complex
          Algorithm.
        </Typography>
        <div className={classes.tabs}>
          <CustomTabs
            isFocused={value.variant === 0}
            onChange={(index) => setValue({ tabIndex: index, variant: 0 })}
            tabs={TABS[0]}
            title="Light Algorithm"
            value={value.tabIndex}
          />
          <CustomTabs
            isFocused={value.variant === 1}
            onChange={(index) => setValue({ tabIndex: index, variant: 1 })}
            tabs={TABS[1]}
            title="Complex Algorithm"
            value={value.tabIndex}
          />
        </div>
        <div className={classes.tabContent}>
          {TABS[value.variant].map(
            (item: { frame: { name: string; img: any }[] }, index: React.Key) => (
              <TabPanel
                key={index}
                className={className[value.variant]}
                index={index}
                value={value.tabIndex}
              >
                <div className={classes.panel}>
                  <div className={classes.block}>
                    <div>
                      <div className={classes.blockContent}>
                        {item.frame[0].img}
                        <Typography dangerouslySetInnerHTML={{ __html: `${item.frame[0].name}` }} />
                      </div>
                      <div className={classes.blockInfo} />
                    </div>
                    <div>
                      <div className={classes.blockContent}>
                        {item.frame[1].img}
                        <Typography dangerouslySetInnerHTML={{ __html: `${item.frame[1].name}` }} />
                      </div>
                      <div className={classes.blockInfo} />
                    </div>
                  </div>
                  <div className={classes.content}>
                    <div>
                      <div>
                        <div>
                          <Typography align="center" variant="h2">
                            Fast Processing
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.block}>
                    <div>
                      <div className={classes.blockContent}>
                        {item.frame[2].img}
                        <Typography dangerouslySetInnerHTML={{ __html: `${item.frame[2].name}` }} />
                      </div>
                      <div className={classes.blockInfo} />
                    </div>
                    <div>
                      <div className={classes.blockContent}>
                        {item.frame[3].img}
                        <Typography dangerouslySetInnerHTML={{ __html: `${item.frame[3].name}` }} />
                      </div>
                      <div className={classes.blockInfo} />
                    </div>
                  </div>
                </div>
              </TabPanel>
            ),
          )}
        </div>
      </Container>
    </section>
  );
};

export default Solutions;
