import { ModuleEncryptedObjectParams } from '../../types';
import { ObjectDescriptorDTO } from './index';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_ENTRIES = 'SET_ENTRIES';


interface OpenModalPayloadInterface {
  data: null | ModuleEncryptedObjectParams;
  type: string;
}

export interface OpenModal {
  type: typeof OPEN_MODAL;
  payload: OpenModalPayloadInterface;
}

export interface CloseModal {
  type: typeof CLOSE_MODAL;
  payload: any;
}

export interface SetEntries {
  type: typeof SET_ENTRIES;
  payload: ObjectDescriptorDTO[];
}

export type AplicationActionTypes =
  | OpenModal
  | CloseModal
  | SetEntries
