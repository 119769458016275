import React, { FC } from 'react';

import LinkedInIcon from '../../../../../assets/images/icons/linkedIn.svg';

import useStyles from './styles';

interface TeamCardInterface {
  firstName: string,
  lastName: string,
  photo: any,
  profession: string,
  linkedIn?: string
}

const TeamCard: FC<TeamCardInterface> = ({ firstName, lastName, photo, profession, linkedIn }) => {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <img className={classes.photo} src={photo} alt={firstName}/>
      <div className={classes.info}>
        <div className={classes.name}>{firstName}</div>
        <div className={classes.name}>{lastName}</div>
        {linkedIn && (
          <a className={classes.link} href={linkedIn} target="_blank">
            <img src={LinkedInIcon} alt="LinkedIn"/>
          </a>
        )}
        <div className={classes.line}/>
        <div className={classes.profession}>{profession}</div>
      </div>
    </div>
  );
};

export default TeamCard;
