import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    title: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            alignItems: "center"
        },
        [theme.breakpoints.only('lg')]: {
            display: 'flex',
            flexDirection: "column",
            alignItems: "flex-start"
        },
        '&>img': {
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(1.6),
            },
            [theme.breakpoints.down('md')]: {
                maxWidth: 40,
                marginRight: theme.spacing(2),
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: theme.spacing(3),
            },
            [theme.breakpoints.up('lg')]: {
                marginRight: theme.spacing(3.625),
            },
        },
        "& span": {
            background: 'linear-gradient(269.9deg, #3C39FB 1.82%, #66A5FE 35.06%, #24D1F3 53.98%, #27EDBE 72.9%, #2AFD9B 100%);',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
        }
    },
}));

export default useStyles;
