import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  solutions: {
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: 50,
      paddingBottom: 60,
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: 145,
      paddingBottom: 120,
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: 131,
      paddingBottom: 100,
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: 31,
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(5),
    },
  },
  subtitle: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(5),
      fontSize: '17px',
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(5),
      maxWidth: 583,
      fontSize: '17px',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(7.8),
      maxWidth: 660,
      fontSize: '16px',
    },
  },
  panel: {
    display: 'flex',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  },
  content: {
    background: 'linear-gradient(251.58deg, #321958 -40.91%, #1B1B23 28.08%, #100D17 100%)',
    borderRadius: '16px',
    padding: '30px',
    marginBottom: '16px',
    '& h2': {
      [theme.breakpoints.down('md')]: {
        fontSize: 28,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 300,
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: '192px',
      padding: 18,
    },
    [theme.breakpoints.only('md')]: {
      height: '300px',
      padding: 30,
      width: 'calc(100% - 44% - 32px)',
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% - 22.7% * 2 - 60px)',
    },
    [theme.breakpoints.up('lg')]: {
      height: '418px',
      padding: theme.spacing(5),
    },
    [theme.breakpoints.up('xl')]: {
      width: 'calc(100% - 17.8% * 2 - 60px)',
    },
    '& > div': {
      position: 'relative',
      overflow: 'hidden',
      height: '100%',
      borderRadius: '8px',
      background: 'linear-gradient(69.01deg, #FC9886 -12.65%, #AA41CE 42.08%, #7A25FA 101.58%)',
      '&> div': {
        height: '140%',
        width: '101%',
        borderRadius: '50%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'linear-gradient(251.58deg, #321958 -40.91%, #1B1B23 28.08%, #100D17 100%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
  blockInfo: {
    display: 'none',
  },
  block: {
    display: 'flex',
    [theme.breakpoints.between('xs', 'sm')]: {
      justifyContent: 'space-between',
      '&> div': {
        width: 'calc(50% - 8px)',
      },
    },
    [theme.breakpoints.only('md')]: {
      width: '22%',
      flexDirection: 'column',
      '&> div': {
        marginBottom: '10px',
      },
    },
    [theme.breakpoints.only('lg')]: {
      width: '22.7%',
      flexDirection: 'column',
      '&> div': {
        marginBottom: '10px',
      },
    },
    [theme.breakpoints.up('xl')]: {
      width: '17.8%',
      flexDirection: 'column',
      '&> div': {
        marginBottom: '10px',
      },
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(251.58deg, #1B1B23 -40.91%, #100D17 100%)',
      borderRadius: '16px',
      textAlign: 'center',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        minHeight: 142,
        padding: '18px 10px 10px 10px',
        marginRight: '16px',
        '&:last-child': {
          marginRight: 0,
        },
      },
      [theme.breakpoints.only('md')]: {
        height: 142,
        padding: '18px 12px 14px 16px',
      },
      [theme.breakpoints.down('lg')]: {
        marginBottom: '16px',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: '22px',
        height: '198px',
      },
      '&>img': {
        marginBottom: '13px',
        [theme.breakpoints.down('lg')]: {
          width: 60,
        },
      },
    },
  },
  blockContent: {
    '& p': {
      padding: '10px 20px',
    },
  },
  tabs: {
    marginBottom: '24px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1040px)': {
      '& .tab': {
        minWidth: '140px',
      },
      flexDirection: 'column',
      '& > div': {
        minWidth: '100%',
      },
      '& > div:first-child': {
        marginRight: '0px !important',
        flexGrow: 1,
      },
    },
    '& > div:first-child': {
      marginRight: '32px',
      '& .tab:nth-child(2) .tab-title': {
        background: 'linear-gradient(90deg, #FD9885 0%, #F35645 51.04%, #EB271F 100%)',
      },
      '& .tab:nth-child(3) .tab-title': {
        background: 'linear-gradient(90deg, #FD9885 0%, #F35645 51.04%, #EB271F 100%)',
      },
      '& .tab:nth-child(4) .tab-title': {
        background: 'linear-gradient(90deg, #FC9886 0%, #AA41CE 47.92%, #7A25FA 100%)',
      },
      '& .tab:nth-child(2).selected': {
        background: 'linear-gradient(255.7deg, #581919 -24.79%, #1B1B23 36.31%, #1E1E26 100%)',
      },
      '& .tab:nth-child(3).selected': {
        background: 'linear-gradient(255.03deg, #5A85FD -56.38%, #1B1B23 20.18%, #1E1E26 100%)',
      },
      '& .tab:nth-child(4).selected': {
        background: 'linear-gradient(255.7deg, #321958 -24.79%, #1B1B23 36.31%, #1E1E26 100%)',
      },
    },
    '& > div:last-child': {
      flexGrow: 1,
      '& .tab': {
        width: '100%',
      },
      '& .tab:nth-child(2) .tab-title': {
        background: 'linear-gradient(90deg, #01AAEC 0%, #0A71F1 47.4%, #144CE0 99.44%)',
      },
      '& .tab:nth-child(3) .tab-title': {
        background: '#29F4B2',
      },
      '& .tab:nth-child(2).selected': {
        background: 'linear-gradient(255.7deg, #3000F7 -24.79%, #1B1B23 36.31%, #1E1E26 100%)',
      },
      '& .tab:nth-child(3).selected': {
        background: 'linear-gradient(254.19deg, #00F6B1 -41.57%, #1B1B23 27.74%, #1E1E26 100%)',
      },
    },
  },
  tabContent: {
    '&>div.light:nth-of-type(1)> div> div> div:nth-of-type(2)': {
      background: 'linear-gradient(251.58deg, #7D231F -40.91%, #1B1B23 28.08%, #100D17 100%)',
      '&>div': {
        background: 'linear-gradient(69.01deg, #FD9885 -12.65%, #F35645 42.08%, #EB271F 101.58%)',
      },
    },
    '&>div.light:nth-of-type(2)> div> div> div:nth-of-type(2)': {
      background: 'linear-gradient(251.58deg, #1E1FA7 -40.91%, #1B1B23 28.08%, #100D17 100%)',
      '&>div': {
        background: 'linear-gradient(90deg, #FD9885 0%, #F35645 51.04%, #EB271F 100%)',
      },
    },
    '&>div.light:nth-of-type(3)> div> div> div:nth-of-type(2)': {
      background: 'linear-gradient(255.7deg, #321958 -24.79%, #1B1B23 36.31%, #1E1E26 100%)',
      '&>div': {
        background: 'linear-gradient(69.01deg, #FC9886 -12.65%, #AA41CE 42.08%, #7A25FA 101.58%)',
      },
    },
    '&>div.complex:nth-of-type(1)> div> div> div:nth-of-type(2)': {
      background: 'linear-gradient(255.7deg, #321958 -24.79%, #1B1B23 36.31%, #1E1E26 100%)',
      '&>div': {
        background: `linear-gradient(90deg, #00FFAA -2339.35%,
              #00F6B1 -2062.75%, #00DCC5 -1602.79%,
            #00B3E5 -1020.06%, #0091FF -599.39%,
            rgba(48, 0, 247, 0.7) 936.33%)`,
      },
    },
    '&>div.complex:nth-of-type(2)> div> div> div:nth-of-type(2)': {
      background: 'linear-gradient(251.58deg, #321958 -40.91%, #1B1B23 28.08%, #100D17 100%)',
      '&>div': {
        background: '#29F4B2',
      },
    },
  },
}));

export default useStyles;
