import { createMuiTheme } from '@material-ui/core/styles';
import { typography } from './typografy';

// breakpoints

const xl = 1280;
const lg = 1024;
const md = 768;
const sm = 575;
const xs = 0;

// colors
const white = '#fff';
const black = '#000';
const primary = '#29F4B2';
const secondary = '#fff';
const background = '#fff';
const grey = '#BDBDBD';
const custom = '#29F4B2';

const spacing = 8;

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    custom: Palette['primary'];
  }
  interface PaletteOptions {
    custom: PaletteOptions['primary'];
  }
}

export const theme = createMuiTheme({
  palette: {
    primary: { main: primary },
    secondary: {
      main: secondary,
      dark: '#C926AC',
    },
    custom: {
      main: custom,
    },
    background: {
      default: background,
    },

  },

  spacing,
  typography,
  breakpoints: {
    values: { xs, sm, md, lg, xl },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: '#fff',
        },
      },
    },
    MuiTypography: {
      paragraph: {
        fontSize: '14px',
        fontWeight: 400,
      },
    },
    MuiInput: {
      root: {
        color: primary,
      },
      underline: {
        '&:before': {
          borderBottomColor: white,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottomColor: primary,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: white,
        "&$focused": {
          color: primary,
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: 400,
        textTransform: 'none',
        fontSize: '15px',
        transition: '0.4s',
      },
      outlined: {
        padding: '10px 10px',
      },
      contained: {
        padding: '10px 10px',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
      textSizeSmall:{
        padding: "8px 10px",
        fontSize: 15,
        fontWeight: 500
      },
      containedSizeSmall: {
        padding: "8px 10px",
        fontSize: 15,
        fontWeight: 500
      },
      containedPrimary: {
        color: '#12121A',
        backgroundColor: primary,
        border: `1px solid ${primary}`,
        '&:hover': {
          border: `1px solid #29f4b2cc`,
          backgroundColor: '#29f4b2cc',
        },
        '&:active': {
          border: `1px solid #29f4b299`,
          backgroundColor: '#29f4b299',
        },
        '&:disabled': {
          border: `1px solid ${grey}`,
          backgroundColor: grey,
          color: white,
        },
      },
      containedSecondary: {
        border: `1px solid ${white}`,
        backgroundColor: white,
        color: '#12121A',
        '&:hover': {
          border: `1px solid ${primary}`,
          backgroundColor: primary,
        },
        '&:active': {
          border: '1px solid #29f4b299',
          backgroundColor: '#29f4b299',
        },
      },
      outlinedSecondary: {
        color: white,
        border: '1px solid #ffffffb3',
        '&:hover': {
          border: `1px solid ${primary}`,
        },
        '&:active': {
          border: '1px solid #29f4b299',
        },
        '&:disabled': {
          border: `1px solid ${grey}`,
          color: grey,
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});
