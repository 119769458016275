import React, { FC } from 'react';
import Container from '../../../../components/Container';
import Title from '../../../../components/Title';
import VideoJS from '../../../../components/Video/index';
import titleImg from '../../../../assets/images/icons/eye.svg';
import preview from '../../../../assets/images/preview.png';

import useStyles from './styles';

const Comparison: FC = () => {
  const videoJsOptions = {
    controls: true,
    responsive: true,
    fluid: true,
    poster: preview,
    sources: [{
      src: 'FULL.mp4',
      type: 'video/mp4',
    }],
  };

  const classes = useStyles();
  return (
    <section className={classes.comparison} id="comparison">
      <Container variant="default">
        <Title img={titleImg} text="Live comparison" type="h2" className={classes.title}/>
      </Container>
      <Container variant="large">
        <div className={classes.player}>
          <VideoJS options={videoJsOptions}/>
        </div>
      </Container>
    </section>
  );
};

export default Comparison;
