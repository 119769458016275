import React, { FC } from 'react';
import { Typography, Box, Tabs, Tab, Grid, useMediaQuery } from '@material-ui/core';
import Title from '../../../../components/Title';
import Container from '../../../../components/Container';
import TabPanel from '../../../../components/TabPanel';
import titleImg from '../../../../assets/images/icons/crop.svg';
import tabOne from '../../../../assets/images/bandwidth.svg';
import tabTwo from '../../../../assets/images/growth.svg';
import tabThree from '../../../../assets/images/shortage.svg';
import tabFour from '../../../../assets/images/royalties.svg';

import useStyles from './styles';

const TABS = [
  {
    name: 'Limited Bandwidth',
    img: tabOne,
    title: '01. Limited Bandwidth',
    text: 'Although video data constitutes 80% of global Internet traffic, the quality of video transmissions could be much higher were it not throttled by the inefficiencies of existing ISP infrastructure. As a result of computational complexities and bandwidth limits imposed on high quality videos, content creators and distributors cannot effectively meet market demand for 4K+ data streams.',
  },
  {
    name: 'Growth of AI',
    img: tabTwo,
    title: '02. Growth of AI',
    text: 'The adoption of AI and machine learning technologies will increase demand pressure on storage and maintenance of multimedia data. This trend is already driving data centre operators to increase their infrastructure development with the winners being those who can find ways to decrease their CAPEX either through optimisations or lower rollout costs.',
  },
  {
    name: 'Hardware Shortage',
    img: tabThree,
    title: '03. Hardware Shortage',
    text: 'In general, the development of any industry that works with media data occurs in two ways. Option 1 - the growth of infrastructure by expanding data centers, which may be limited for various reasons. Currently, there is a shortage of semiconductors due to unexpected demand growth. Option 2 - optimisation of how media data is processed.',
  },
  {
    name: 'Tangled Royalties',
    img: tabFour,
    title: '04. Tangled Royalties',
    text: 'The most commonly used video codecs (AVC and HEVC) have multiple licensors, making payments of licensing fees a complicated and often litigious exercise. To solve this issue, several companies formed an alliance to create a new license-free AV1 codec but this too ended recently in a breakdown in negotiations around distribution. If this is a case of "too many cooks in the kitchen", there may be some merit in a singularly-held codec technology with universal application where the licensing rights are clear, transparent, and applicable to the extend range of the technology\'s uses. This is the IP solution that POLAR offers.',
  },
];

const Codec: FC = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const matches = useMediaQuery('(max-width:767.9px)');

  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <section className={classes.codec} id="codec">
      <Container variant="default">
        <Title img={titleImg} type="h2" className={classes.title}>
          <Box component="div" display={{ xs: 'inline-block ', lg: 'block' }}mr={1}>
            {' '}
            Why a New Codec{' '}
          </Box>
          Technology is Needed
        </Title>
        <Typography className={classes.subtitle} variant="subtitle1">
          Technical challenges across a range of multimedia applications
          are limiting the growth potential of numerous industries.
        </Typography>
        <Tabs
          className={classes.tabs}
          onChange={handleChange}
          scrollButtons={matches ? 'on' : 'off'}
          textColor="primary"
          value={value}
          variant="fullWidth"
        >
          {TABS.map((item, index) => (
            <Tab key={item.name + index} label={item.name} />
          ))}
        </Tabs>

        {TABS.map((item, index) => (
          <TabPanel key={index} index={index} value={value}>
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item md={6} xs={12}>
                <div className={classes.preview}>
                  <img src={item.img} alt="img" />
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className={classes.content}>
                  <Typography variant="h3">{item.title}</Typography>
                  <Typography>{item.text}</Typography>
                </div>
              </Grid>
            </Grid>
          </TabPanel>
        ))}
      </Container>
    </section>
  );
};

export default Codec;
