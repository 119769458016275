import React, {FC} from 'react';

import Container from "../../../../../../components/Container";
import useStyles from "../styles";

const Streaming: FC = () => {
  const classes = useStyles();

  return (
    <Container variant="large">
      <div className={classes.section}>
        <div className={classes.title}>
          <span className="greenBlueBg">3</span>
          <div className="greenBlue">POLAR Streaming Codec</div>
        </div>
        <div className="rows">
          <div className="row row-1">
            <div className="caption greenBlueBg">
              Patented concept
            </div>
            <div className="caption greenBlueBg mt8">
              Image level prototype
            </div>
          </div>
          <div className="row row-2">
            <span className="mini-caption greyBg">Q2</span>
            <div className="text">Launch of development</div>
          </div>
          <div className="row row-3">
            <span className="mini-caption greyBg mr4">Q2</span>
            <div className="text">Presentation of a working prototype</div>
          </div>
          <div className="row row-4">
            <span className="mini-caption greyBg mr4">Q4</span>
            <span className="mini-caption greenBlueBg">$$$</span>
            <div className="text">Commercial launch</div>
          </div>
          <div className="row row-5">
            <span className="mini-caption greenBlueBg">$$$</span>
            <div className="text">Expanding the target use in various industries</div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Streaming;
