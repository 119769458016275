import React, { FC } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { Typography, Chip, IconButton } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon, ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import Title from '../../../../components/Title';
import Container from '../../../../components/Container';
import titleImg from '../../../../assets/images/icons/box.svg';
import previewJpg from '../../../../assets/images/jpg-preview.jpg';
import previewPng from '../../../../assets/images/png-preview.png';

import useStyles from './styles';

const Concept: FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.concept} id="poc">
      <Container variant="default">
        <Title className={classes.title} img={titleImg} text="Proof of Concept" type="h1" />
        <Typography className={classes.subtitle} variant="subtitle1">
          POLAR is building the technological foundations for a suite of codec product lines.
          As soon as working prototypes are developed, they will be rigorously tested to
          demonstrate their proprietary strengths against competitor products. Below, you are
          invited to move the slide  and witness the difference in quality that the POLAR codec
          exhibits compared to JPEG at levels of extreme image compression.
        </Typography>
      </Container>
      <Container variant="large">
        <div className={classes.slider}>
          <ReactCompareSlider
            handle={
              <div className={classes.controls}>
                <IconButton className={classes.btn}>
                  <ArrowBackIcon />
                  <ArrowForwardIcon />
                </IconButton>
              </div>
            }
            itemOne={<ReactCompareSliderImage alt="Image one" src={previewJpg} />}
            itemTwo={<ReactCompareSliderImage alt="Image two" src={previewPng} />}
          />
          <div className={classes.chipWrapper}>
            <Chip label="JPEG" />
            <Chip label="POLAR" className={classes.polarChip} />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Concept;
