import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  concept: {
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: 60,
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: 120,
      paddingBottom: 45,
    },
    [theme.breakpoints.only('lg')]: {
      paddingTop: 50,
      paddingBottom: 70,
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: 130,
      paddingBottom: 130,
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4.25),
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(4),
      flexDirection: 'row!important',
    },
  },
  subtitle: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4.25),
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 740,
    },
    [theme.breakpoints.only('lg')]: {
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(8.4),
    },
  },
  slider: {
    borderRadius: '16px',
    overflow: 'hidden',
    position: 'relative',
    '&> div:first-child': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        height: 400,
      },
      [theme.breakpoints.up('lg')]: {
        height: 654,
      },
    },
  },
  controls: {
    display: 'grid',
    placeContent: 'center',
    height: '100%',
    position: 'relative',
    '&:before': {
      content: '" "',
      position: 'absolute',
      width: 2,
      height: '100%',
      display: 'block',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: '#fff',
    },
  },
  btn: {
    color: '#fff',
    '& .MuiSvgIcon-root': {
      margin: '0px 10px',
    },
  },
  chipWrapper: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    pointerEvents: 'none',
    padding: '0px 20px',
    [theme.breakpoints.down('md')]: {
      padding: '0px 8px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0px 20px',
    },
    '& .MuiChip-root': {
      backgroundColor: 'transparent',
      border: '1px solid white',
      color: '#fff',
      fontSize: 12,
      padding: '4px 3px',
      height: 24,
      lineHeight: 24
    },
  },
  polarChip: {
    color: 'black !important',
    background: `${theme.palette.primary.main} !important`,
    border: 'none !important',
    fontWeight: 'bold'
  }
}));

export default useStyles;
