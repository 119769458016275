import React, { FC } from 'react';
import Container from '../../../../components/Container';
import Title from '../../../../components/Title';
import Card from '../../../../components/Card/index';
import titleImg from '../../../../assets/images/icons/zap.svg';
import fileText from '../../../../assets/images/icons/file-text.svg';
import cpu from '../../../../assets/images/icons/cpu.svg';
import database from '../../../../assets/images/icons/database.svg';
import monitor from '../../../../assets/images/icons/fmonitor.svg';
import union from '../../../../assets/images/icons/union.svg';

import useStyles from './styles';

const PANELS_DATA = [
  {
    title: 'Clear licensing',
    subtitle:
      'POLAR\'s unique approach, based on colour segregation rather than on brightness and geometric differentiation, has led to a patent filing for a novel technique of multimedia signal processing that spans the entire process of compression, from colour division and approximation to lossless compression and error processing.',
    icon: fileText,
  },
  {
    title: 'Low hardware requirements',
    subtitle:
      'Because POLAR\'s codec algorithms do not require block computations, their calculation is  scalable at a much lower aggregate cost. With POLAR, multiple calculations can be done in parallel without the delays experienced by competing codec technologies that require additional hardware in order to achieve equivalent processing speeds.',
    icon: cpu,
  },
  {
    title: 'Up to ',
    textPrimary: '30% lower bandwidth consumption',
    subtitle:
      'We gained this result on the pre-standard compression stage that any codec has, and this process can be part of any codec, even though it works best for our concept. This technology is the main patent of POLAR.',
    icon: database,
  },
  {
    title: 'Up to ',
    textPrimary: '16K support',
    subtitle:
      "The parallel nature of POLAR's codec allows for a linear growth in complexity as data volume increases. This is in contrast to other codecs which experience exponential growth in complexity and is the reason behind the slow growth in 8K+ technology. POLAR's innovations greatly accelerate the possibility for adoption of AR/VR applications and holograms as mainstream technologies.",
    icon: monitor,
  },
  {
    title: 'Environmentally friendly',
    subtitle:
      'As the demand for video data continues to grow, the multimedia industry will continue to face the challenge of developing new technologies whilst remaining environmentally friendly. POLAR\'s low complexity solutions require much less energy than those of its competitors leaving the company with a much smaller carbon footprint.',
    icon: union,
  },
  {
    title: 'Want to learn more about Polar?',
    subtitle: 'Contact Us for more information.',
    contact: true,
  },
];

const Achievements: FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.achievements} id="achievements">
      <Container variant="default">
        <Title img={titleImg} text="Our Achievements" type="h1" className={classes.title} />
        <div className={classes.x}>
          <div className={classes.panelBox}>
            {PANELS_DATA.map((item, index) => (
              <Card
                key={index}
                contact={item.contact}
                icon={item.icon}
                subtitle={item.subtitle}
                textPrimary={item.textPrimary}
                title={item.title}
              />
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};
export default Achievements;
