import React, { FC } from 'react';
import { Box } from '@material-ui/core';

import Container from '../../../../components/Container';
import Title from '../../../../components/Title';
import usersImg from '../../../../assets/images/icons/users.svg';

import { headTeamList, teamList } from './teamList';
import TeamCard from './card';
import useStyles from './styles';

const Team: FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.team}>
      <Container variant="default">
        <Title className={classes.title} img={usersImg} type="h2">
          <Box component="span">Polar </Box> Team
        </Title>
      </Container>
      <Container variant="large">
        <div className={classes.cards}>
          {headTeamList.map((item, key) => (
            <TeamCard
              key={key}
              {...item}
            />
          ))}
        </div>
        <div className={classes.cards}>
          {teamList.map((item, key) => (
            <TeamCard
              key={key}
              {...item}
            />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Team;
