import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  comparison: {
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: '114px',
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: '216px',
    },
    [theme.breakpoints.only('lg')]: {
      paddingTop: 130,
      paddingBottom: 133,
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: 154,
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(5.875),
    },
    [theme.breakpoints.only('lg')]: {
      flexDirection: 'row!important',
      marginBottom: 29,
      '&>img': {
        marginBottom: '0!important',
      },
    },
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(7.375),
    },
  },
  player: {
    position: 'relative',
    paddingTop: '56.25%',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      borderRadius: '3px',
    },
    [theme.breakpoints.up('xl')]: {
      borderRadius: '16px',
    },
    '&> div': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      lineHeight: 0,
    },
    '& .vjs-big-play-button': {
      display: "none"
    },
    '& .vjs-poster': {
      backgroundSize: 'cover'
    }
  },
}));

export default useStyles;
