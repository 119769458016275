import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  relative: {
    position: 'relative',
    marginTop: '24px',
  },
  tabsWrapper: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    padding: '27px 32px 24px',
    border: '1px solid #ffffff33',
    borderRadius: '8px',
    display: 'flex',
    '@media (max-width: 750px)': {
      padding: '20px',
    },
    '& .tab': {
      marginRight: '16px',
      border: '1px solid rgba(139, 139, 139, 0.4)',
      borderRadius: '8px',
      height: 59,
      padding: '0 24px !important',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      cursor: 'pointer',
      textAlign: 'center',
      flexGrow: 1,
    },
    '& .tab:last-child': {
      marginRight: 0,
    },
    '& .tab-title': {
      fontSize: 15,
      lineHeight: 1.1,
      WebkitBackgroundClip: 'text !important',
      WebkitTextFillColor: 'transparent',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
    '& .tab-subtitle': {
      fontSize: 13,
      textTransform: 'uppercase',
      opacity: 0.5,
      lineHeight: 1,
      marginTop: 6,
    },
  },
  title: {
    position: 'absolute',
    top: '-12px',
    background: '#100d17',
    padding: '0 10px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '15px',
  },
}));

export default useStyles;
