import React, { FC } from 'react';
import { Typography, Button, Hidden } from '@material-ui/core';
import { ArrowUpward as ArrowUpwardIcon, SaveAlt as SaveAltIcon } from '@material-ui/icons';
import Container from '../Container';
import ContactForm from './form';
import useStyles from './styles';

const Footer: FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer} id="footer">
      <Container variant="large">
        <div className={classes.caption}>
          <Hidden lgDown>
            <Button
              className={classes.btnDown}
              color="secondary"
              startIcon={<ArrowUpwardIcon color="primary" />}
              variant="text"
              href="#banner"
            >
              Back to the top
            </Button>
          </Hidden>
        </div>
        <div className={classes.content}>
          <div className={classes.contact}>
            <Typography variant="h1">Contact Us</Typography>
            <ContactForm />
          </div>
          <div className={classes.details}>
            <Typography variant="h3">
              Please also download our corporate presentation to find out how you can get involved.
            </Typography>
            <Button
              download
              href="docs/Polar-presentation.pdf"
              color="secondary"
              startIcon={<SaveAltIcon />}
              variant="outlined"
            >
              Download Presentation
            </Button>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
