import React, { FC } from 'react';
import {
    Typography,
    Button,
} from '@material-ui/core';

import useStyles from './styles'
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';

interface CardProps {
    title?: string,
    subtitle?: string,
    icon?: string,
    contact?: boolean,
    textPrimary?: string
}

const Card: FC<CardProps> = ({
    title,
    subtitle,
    icon,
    contact,
    textPrimary,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.root} >
            <div className={classes.wrapper}>
                <div className={classes.icon}>
                    {icon && <img alt="icon" src={icon} />}
                </div>
                <div className={classes.title}>
                    <Typography component="span" variant="h3">{title}</Typography>
                    {textPrimary && <Typography color="primary" component="span" variant="h3">{textPrimary}</Typography>}
                </div>
                <div className={classes.subtitle}>{subtitle}</div>
            </div>
            {contact && (
              <Button
                className={classes.contactUs}
                color="primary"
                href="#footer"
                endIcon={<ArrowForwardIcon />}
                fullWidth
                variant="contained"
              >
                Contact Us
              </Button>
            )}
        </div >
    );
};

export default Card;
