export const COLUMNS_FOOTER1 = [
  {
    Header: <span>POLAR superiority over H264</span>,
    accessor: 'row1',
    columns: [
      {
        Header: '',
        accessor: 'column0',
      },
    ],
  },
  {
    Header: <span className="primary-color">6.64X</span>,
    accessor: 'row2',
    columns: [
      {
        Header: '',
        accessor: 'column1',
      },
      {
        Header: '',
        accessor: 'column2',
      },
    ],
  },
  {
    Header: <span className="primary-color">200%</span>,
    accessor: 'row3',
    columns: [
      {
        Header: '',
        accessor: 'column3',
      },
      {
        Header: '',
        accessor: 'column4',
      },
    ],
  },
  {
    Header: <span className="primary-color">no visual difference</span>,
    accessor: 'row4',
    columns: [
      {
        Header: '',
        accessor: 'column5',
      },
      {
        Header: '',
        accessor: 'column6',
      },
    ],
  },
  {
    Header: <span className="primary-color">195%</span>,
    accessor: 'row5',
    columns: [
      {
        Header: '',
        accessor: 'column7',
      },
      {
        Header: '',
        accessor: 'column8',

      },
    ],
  },
  {
    Header: <span className="primary-color">6.64X</span>,
    accessor: 'row6',
    columns: [
      {
        Header: '',
        accessor: 'column9',
      },
      {
        Header: '',
        accessor: 'column10',

      },
    ],
  },
];
