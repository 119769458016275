import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  production: {
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: '114px',
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: '216px',
    },
    [theme.breakpoints.only('lg')]: {
      paddingTop: '130px',
      paddingBottom: 50,
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '130px',
      paddingBottom: '130px',
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(7.5),
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(5.75),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(10),
      flexDirection: 'row!important',
    },
  },
  modal: {
    '& .MuiPaper-root': {
      background: 'linear-gradient(270deg, #2E2C35 0%, #47464D 100%)',
    },
    '& .MuiDialogContent-root': {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('xl')]: {
        '& .video-js': {
          minWidth: '976px',
        },
      },
    },
  },
  p: {
    display: 'flex',
    flexFlow: 'wrap',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
    },
    '&> div:last-child': {
      [theme.breakpoints.up('lg')]: {
        marginRight: 0,
      },
    },
  },
  p2: {
    marginTop: '60px',
    display: 'flex',
    flexFlow: 'wrap',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-start',
    },
    '&> div:last-child': {
      [theme.breakpoints.up('lg')]: {
        marginRight: 0,
      },
    },
  },
  c: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexFlow: 'wrap',
    },
    '&> div': {
      color: '#ffffff99',
      fontSize: 15,
      marginBottom: 8,
      marginRight: 15,
    },
    '&> span': {
      fontSize: 15,
      marginLeft: 15,
    },
    '&> span:nth-of-type(1), &> span:last-child': {
      marginLeft: 0,
    },
  },
  a: {
    minWidth: 200,
    marginBottom: 24,
    marginRight: 40,
    [theme.breakpoints.up('lg')]: {
      marginRight: 24,
    },
    display: 'flex',
    ' & .MuiChip-root ': {
      background: 'linear-gradient(270deg, #2E2C35 0%, #47464D 100%)',
      color: '#fff',
      marginRight: 10,
      fontWeight: 600,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '120px',
      marginRight: '8px',
    },
    [theme.breakpoints.only('md')]: {
      width: '146px',
      marginRight: '8px',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: ' 30px',
      width: '200px',
    },
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      bottom: 0,
      zIndex: 1,
      backgroundColor: '#100D17',
      padding: '10px 10px 0px 0px',
      borderRadius: 6,
    },
  },
  item: {
    position: 'relative',
    marginBottom: '15px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  preview: {
    height: 100,
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      width: 110,
    },
    [theme.breakpoints.only('md')]: {
      width: 136,
    },
    [theme.breakpoints.up('lg')]: {
      width: 190,
    },
  },
  play: {
    width: 24,
    height: 24,
    position: 'absolute',
    top: 32,
    left: '50%',
    transform: 'translateX(-50%)',
    background: '#12121a2e!important',
    '&:hover': {
      background: '#00000070!important',
    },
  },
  chip: {
    position: 'absolute',
    bottom: '10px',
    left: 0,
    right: 0,
    margin: '0 auto',
    backgroundColor: '#000',
    color: '#fff',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      width: 94,
      fontSize: 10,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.only('md')]: {
      width: 120,
    },
    [theme.breakpoints.up('lg')]: {
      width: 174,
    },
  },
  content: {
    overflowX: 'hidden',
    overflowY: 'visible',
    height: 482,
    [theme.breakpoints.up('lg')]: {
      height: 488,
    },
    [theme.breakpoints.down('lg')]: {
      zIndex: 0,
    },
  },
  pointerEventsNone: {
    [theme.breakpoints.up('xl')]: {
      pointerEvents: 'none',
    },
  },
  box: {
    display: 'flex',
    overflowX: 'auto',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 130,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      paddingLeft: 150,
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: '10px',
    },
    [theme.breakpoints.only('lg')]: {
      paddingLeft: 207,
      paddingBottom: '10px',
    },
    '& > div': {
      marginRight: '30px',
      position: 'relative',
      [theme.breakpoints.down('lg')]: {
        width: '407px',
        flexShrink: 0,
      },
      [theme.breakpoints.up('lg')]: {
        width: '520px',
      },
      '&:last-child': {
        marginRight: '0px',
      },
      '&:nth-of-type(2)': {
        borderRadius: 6,
        overflow: 'hidden',
        padding: 1,
        position: 'relative',
        '&:before': {
          content: " '' ",
          display: 'block',
          position: 'absolute',
          height: 'calc(100% - 42px)',
          width: 'calc(100% + 4px)',
          top: -1,
          right: -1,
          bottom: -1,
          left: -1,
          'z-index': 0,
          borderRadius: 'inherit',
          background:
            // eslint-disable-next-line max-len
            'linear-gradient(269.9deg, #3C39FB 1.82%, #66A5FE 35.06%, #24D1F3 53.98%, #27EDBE 72.9%, #2AFD9B 100%)',
        },
      },
    },
  },
  hideBorderBottom: {
    marginBottom: '0 !important',
  },
  customTab: {
    margin: '20px 0 15px 0 !important',
    '& > div > div': {
      borderRadius: '8px 8px 0 0 !important',
      padding: '14px 18px 13px',
    },
  },
  secondTable: {
    overflowX: 'hidden',
    position: 'relative',
  },
  customContent: {
    border: '1px solid #ffffff33',
    overflowY: 'hidden',
    [theme.breakpoints.down('lg')]: {
      overflowX: 'scroll',
      height: '460px',
    },
  },
  customBox: {
    minWidth: 1024,
    width: '100%',
    padding: '0px 70px 36px',
    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
    '& > div > div > table > tbody > tr > td': {
      padding: '15px 0',
      textAlign: 'center',
    },
  },
  tableIndex1: {
    height: '420px',
    [theme.breakpoints.down('lg')]: {
      height: '400px',
    },
  },
  tabs: {
    marginBottom: '24px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '500px',
    '@media (max-width: 1040px)': {
      '& .tab': {
        minWidth: '140px',
      },
      // flexDirection: 'column',
      '& > div': {
        minWidth: '100%',
      },
      '& > div:first-child': {
        marginRight: '0px !important',
        flexGrow: 1,
      },
    },
    '& > div:first-child': {
      marginRight: '32px',
      '& .tab:nth-child(2) .tab-title': {
        background: 'linear-gradient(90deg, #FD9885 0%, #F35645 51.04%, #EB271F 100%)',
      },
      '& .tab:nth-child(3) .tab-title': {
        background: 'linear-gradient(90deg, #FD9885 0%, #F35645 51.04%, #EB271F 100%)',
      },
      '& .tab:nth-child(4) .tab-title': {
        background: 'linear-gradient(90deg, #FC9886 0%, #AA41CE 47.92%, #7A25FA 100%)',
      },
      '& .tab:nth-child(2).selected': {
        background: 'linear-gradient(255.7deg, #581919 -24.79%, #1B1B23 36.31%, #1E1E26 100%)',
      },
      '& .tab:nth-child(3).selected': {
        background: 'linear-gradient(255.03deg, #5A85FD -56.38%, #1B1B23 20.18%, #1E1E26 100%)',
      },
      '& .tab:nth-child(4).selected': {
        background: 'linear-gradient(255.7deg, #321958 -24.79%, #1B1B23 36.31%, #1E1E26 100%)',
      },
    },
    '& > div:last-child': {
      flexGrow: 1,
      '& .tab': {
        width: '100%',
      },
      '& .tab:nth-child(2) .tab-title': {
        background: 'linear-gradient(90deg, #01AAEC 0%, #0A71F1 47.4%, #144CE0 99.44%)',
      },
      '& .tab:nth-child(3) .tab-title': {
        background: '#29F4B2',
      },
      '& .tab:nth-child(2).selected': {
        background: 'linear-gradient(255.7deg, #3000F7 -24.79%, #1B1B23 36.31%, #1E1E26 100%)',
      },
      '& .tab:nth-child(3).selected': {
        background: 'linear-gradient(254.19deg, #00F6B1 -41.57%, #1B1B23 27.74%, #1E1E26 100%)',
      },
    },
  },
  tabContent: {},
  subTab: {
    padding: '10px 30px',
    borderBottom: '2px solid #ffffff4d',
    fontSize: '15px',
    fontFamily: 'TT Hoves',
    fontWeight: 600,
    cursor: 'pointer',
    margin: '0 !important',
    minWidth: '150px !important',
    textAlign: 'center',
    color: '#ffffffa6',
    flexGrow: 1,
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
    },
  },
  selectedTab: {
    borderBottom: '2px solid grey',
    color: '#29F4B2',
  },
  paperHead: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingBottom: theme.spacing(3.75),
    '&> img': {
      marginRight: 16,
    },
    '&> h3': {
      color: '#fff',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      display: 'block',
      width: '100%',
      height: '4px',
      borderRadius: '10px',
    },
    '&.default:after': {
      backgroundImage: 'linear-gradient(90deg, #FD9885 0%, #F35645 51.04%, #EB271F 100%)',
    },
    '&.primary:after': {
      backgroundImage:
        // eslint-disable-next-line max-len
        'linear-gradient(269.9deg, #3C39FB 1.82%, #66A5FE 35.06%, #24D1F3 53.98%, #27EDBE 72.9%, #2AFD9B 100%)',
    },
  },
  btnGroup: {
    display: 'flex',
    maxWidth: '400px',
  },
  btnDesc: {
    width: 500,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 20,
    [theme.breakpoints.down('md')]: {
      width: 300,
    },
  },
}));

export default useStyles;
