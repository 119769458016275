import React, {FC} from 'react';

import Container from "../../../../../../components/Container";
import useStyles from "./styles";

const Timeline: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.timeline}>
      <Container variant="large">
        <div className="rows">
          <div className="row row-1">
            <div className="time">
              2019-2021
            </div>
            <div className="line"/>
          </div>
          <div className="line"/>
          <div className="row row-2">
            <div className="time">
              2022
            </div>
            <div className="line"/>
          </div>
          <div className="line"/>
          <div className="row row-3">
            <div className="time">
              2023
            </div>
            <div className="line"/>
          </div>
          <div className="line"/>
          <div className="row row-4">
            <div className="time">
              2024
            </div>
            <div className="line"/>
          </div>
          <div className="line"/>
          <div className="row row-5">
            <div className="time">
              2025-2026
            </div>
            <div className="line"/>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Timeline;
