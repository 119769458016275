export const typography = {
  fontFamily: 'TT Hoves',
  color: '#fff',
  h1: {
    fontWeight: 600,
    lineHeight: 1.1,
    '@media (max-width: 410px)': {
      fontSize: '40px !important',
    },
    '@media (max-width: 1023px)': {
      fontSize: '56px',
    },
    '@media (min-width: 1024px)': {
      fontSize: '100px',
    },
  },
  h2: {
    fontWeight: 600,
    lineHeight: 1.1,
    '@media (max-width: 1023px)': {
      fontSize: '40px',
    },
    '@media (min-width: 1024px)': {
      fontSize: '60px',
    },
  },
  h3: {
    fontWeight: 600,
    lineHeight: 1.1,
    '@media (max-width: 1023px)': {
      fontSize: '28px',
    },
    '@media (min-width: 1024px)': {
      fontSize: '32px',
    },
  },
  h4: {
    fontWeight: 600,
    lineHeight: 1.5,
    '@media (max-width: 1023px)': {
      fontSize: '20px',
    },
    '@media (min-width: 1024px)': {
      fontSize: '24px',
    },
  },
  h5: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.6,
  },
  h6: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.6,
  },
  subtitle1: {
    fontWeight: 400,
    lineHeight: 1.5,
    '@media (max-width: 1023px)': {
      fontSize: 17,
    },
    '@media (min-width: 1024px)': {
      fontSize: 22,
    },
  },
};
