import { Chip } from '@material-ui/core';
import { ReactChild, ReactFragment, ReactPortal } from 'react';

export const COLUMNS2 = [
  {
    Header: ' ',
    columns: [
      {
        Header: '',
        accessor: 'column0',
        Cell: function renderColumn(params: {
          value: boolean |
          ReactChild | ReactFragment | ReactPortal;
        }): JSX.Element {
          return <Chip className="chips" label={params.value} />;
        },
      },
    ],
  },
  {
    Header: 't/sec',
    columns: [
      {
        Header: <Chip className="chips" label="H264" style={{ width: '70px' }} />,
        accessor: 'column1',
      },
      {
        Header: (
          <Chip
            className="chips primary"
            label="PHC 1"
            style={{ width: '70px' }}
          />
        ),
        accessor: 'column2',
        Cell: function renderColumn(params: {
          value: boolean
          | ReactChild | ReactFragment | ReactPortal;
        }): JSX.Element {
          return <span className="primary-color">{params.value}</span>;
        },
      },
    ],
  },
  {
    Header: 'size/Mb',
    columns: [
      {
        Header: <Chip className="chips" label="H264" style={{ width: '70px' }} />,
        accessor: 'column3',
      },
      {
        Header: (
          <Chip
            className="chips primary"
            label="PHC 1"
            style={{ width: '70px' }}
          />
        ),
        accessor: 'column4',
        Cell: function renderColumn(params: {
          value: boolean
          | ReactChild | ReactFragment | ReactPortal;
        }): JSX.Element {
          return <span className="primary-color">{params.value}</span>;
        },
      },
    ],
  },
  {
    Header: 'PSNR',
    columns: [
      {
        Header: <Chip className="chips" label="H264" style={{ width: '70px' }} />,
        accessor: 'column5',
      },
      {
        Header: (
          <Chip
            className="chips primary"
            label="PHC 1"
            style={{ width: '70px' }}
          />
        ),
        accessor: 'column6',
        Cell: function renderColumn(params: {
          value: boolean
          | ReactChild | ReactFragment | ReactPortal;
        }): JSX.Element {
          return <span className="primary-color">{params.value}</span>;
        },
      },
    ],
  },
  {
    Header: 'Lframe',
    columns: [
      {
        Header: <Chip className="chips" label="H264" style={{ width: '70px' }} />,
        accessor: 'column7',
      },
      {
        Header: (
          <Chip
            className="chips primary"
            label="PHC 1"
            style={{ width: '70px' }}
          />
        ),
        accessor: 'column8',
        Cell: function renderColumn(params: {
          value: boolean
          | ReactChild | ReactFragment | ReactPortal;
        }): JSX.Element {
          return <span className="primary-color">{params.value}</span>;
        },
      },
    ],
  },
];
