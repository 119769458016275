import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Header from '../../components/Header';
import Banner from './sections/banner';
import Codec from './sections/codec';
import Solutions from './sections/solutions';
import Achievements from './sections/achievements';
import Concept from './sections/concept';
import Production from './sections/production';
import Comparison from './sections/comparison';
import Team from './sections/team';
import Plans from './sections/plans';

import Footer from '../../components/Footer';
import overlayBg from '../../assets/images/background-variant-1.png';
import smallBg from '../../assets/images/background.png';
import Loader from '../../components/Loader';
import backgroundImg from '../../assets/images/footer-back.png';

const useStyles = makeStyles(theme => ({
  appWrapper: {
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#100D17',
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'top right',
      backgroundImage: `url(${smallBg})`,
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${overlayBg})`,
    },
    [theme.breakpoints.only('md')]: {
      backgroundSize: 'auto',
      backgroundPosition: 'top right;',
    },
    [theme.breakpoints.only('lg')]: {
      backgroundSize: 'auto',
      backgroundPosition: 'top right;',
    },
    [theme.breakpoints.up('xl')]: {
      backgroundSize: 'auto',
      backgroundPosition: 'top right;',
    },
  },
  bottomBg: {
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    [theme.breakpoints.up('lg')]: {
      backgroundSize: 'cover',
    },
  },
}));

const Home: FC = () => {
  const classes = useStyles();
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    setTimeout(() => setSpinner(false), 2000);
  }, []);

  return (
    <div className={classes.appWrapper}>
      <Loader load={spinner}/>
      <Header/>
      <Banner/>
      <Codec/>
      <Solutions/>
      <Achievements/>
      <Concept/>
      <Production/>
      <Comparison/>
      <Team/>
      <div className={classes.bottomBg}>
        <Plans/>
        <Footer/>
      </div>
    </div>
  );
};

export default Home;
