import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  timeline: {
    display: 'flex',
    alignItems: 'center',
    '& .time': {
      fontSize: '16px',
      color: '#29F4B2',
      background: '#282631',
      padding: '2px 14px',
      borderRadius: '3px',
      display: 'inline-block',
      margin: '0 8px'
    },
    '& .line': {
      height: '0.6px',
      background: '#FFFFFF',
      opacity: '0.3',
      flexGrow: 1
    },
    '& .rows': {
      display: 'flex',
      alignItems: 'center'
    },
    '& .row': {
      width: '210px',
      minWidth: '210px',
      display: 'flex',
      alignItems: 'center'
    },
    '& .row:last-child': {
      width: '190px',
      minWidth: '190px',
    },
  }
}));

export default useStyles;
