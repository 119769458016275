import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  section: {
    borderTop: '1px solid rgba(255, 255, 255, 0.1)',
    paddingTop: '16px',
    paddingBottom: '24px',
    '@media (max-width: 1090px)': {
      width: 'fit-content',
    },
    '& .lightRedBg': {
      background: 'linear-gradient(90deg, #FD9885 0%, #F35645 51.04%, #EB271F 100%)'
    },
    '& .lightRed': {
      background: 'linear-gradient(90deg, #FD9885 0%, #F35645 51.04%, #EB271F 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    '& .greenBlueBg': {
      background: 'linear-gradient(269.9deg, #3C39FB 1.82%, #66A5FE 35.06%, #24D1F3 53.98%, #27EDBE 72.9%, #2AFD9B 100%)'
    },
    '& .greenBlue': {
      background: 'linear-gradient(269.9deg, #3C39FB 1.82%, #66A5FE 35.06%, #24D1F3 53.98%, #27EDBE 72.9%, #2AFD9B 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    '& .orangePurpleBg': {
      background: 'linear-gradient(90deg, #FC9886 0%, #AA41CE 47.92%, #7A25FA 100%)'
    },
    '& .orangePurple': {
      background: 'linear-gradient(90deg, #FC9886 0%, #AA41CE 47.92%, #7A25FA 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    '& .blueLight': {
      background: 'linear-gradient(270deg, #01AAEC 0%, #0A71F1 49.39%, #144CE0 103.62%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    '& .lightBlueBg': {
      background: 'linear-gradient(90deg, #01AAEC 0%, #0A71F1 47.4%, #144CE0 99.44%)'
    },
    '& .greyBg': {
      background: 'linear-gradient(270deg, rgba(46, 44, 53, 0.8) 0%, rgba(71, 70, 77, 0.8) 100%)'
    },
    '& .rows': {
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& .row': {
      width: '190px',
      minWidth: '190px',
      marginRight: '20px'
    },
    '& .row:last-child': {
      marginRight: 0
    },
    '& .caption': {
      fontSize: '13px',
      textAlign: 'center',
      textTransform: 'uppercase',
      borderRadius: '50px',
      height: '24px',
      lineHeight: '24px'
    },
    '& .mini-caption': {
      fontSize: '13px',
      padding: '2px 8px',
      textAlign: 'center',
      borderRadius: '20px'
    },
    '& .text': {
      fontSize: '13px',
      fontWeight: 600,
      marginBottom: '16px',
      marginTop: '4px'
    },
    '& .mr4': {
      marginRight: '4px'
    },
    '& .mt8': {
      marginTop: '8px'
    },
    '& .border': {
      border: '1px solid #FFFFFF'
    }
  },
  title: {
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      width: '30px',
      height: '30px',
      fontSize: '24px',
      borderRadius: '50%',
      textAlign: 'center',
      lineHeight: '30px',
      marginRight: '20px'
    },
    '& div': {
      fontSize: '24px',
      fontWeight: 600
    }
  }
}));

export default useStyles;
