import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import useHorizontalScroll from '../../hooks/useHorizontalScroll';

interface CustomTabsInterface {
  tabs: any[],
  title?: string,
  value: number,
  onChange: Function,
  isFocused: boolean
}

const CustomTabs: FC<CustomTabsInterface> = ({ tabs, title = '', value, isFocused, onChange }) => {
  const classes = useStyles();
  const scrollRef = useHorizontalScroll<HTMLDivElement>();
  return (
    <div className={classes.relative}>
      <div ref={scrollRef} className={classes.tabsWrapper}>
        <div className={classes.title}>{title}</div>
        {tabs.map((item, index) => (
          <div
            key={index}
            onClick={() => onChange(index)}
            className={`tab ${index === value && isFocused ? 'selected' : ''}`}
          >
            <Typography variant="h4" className="tab-title">{item.name}</Typography>
            {item.subtitle && (
              <span className="tab-subtitle">{item.subtitle}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomTabs;
