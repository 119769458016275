import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        border: '1px solid #ffffff4d',
        borderRadius: '10px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    wrapper: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            padding: '24px 26px 24px 24px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '24px 30px 22px 21px',
        },
    },
    icon: {
        width: 40,
        height: 40,
        marginBottom: 19
    },
    title: {
        minHeight: '114px',
        '&> .MuiTypography-h3': {
            lineHeight: 1.2
        },
        [theme.breakpoints.only('md')]: {
            marginBottom: "5px"
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: 11
        },
    },
    subtitle: {
        fontSize: 17,
        lineHeight: 1.55,
        flexGrow: 1,
    },
    contactUs: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    }
}));

export default useStyles;
