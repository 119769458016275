import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
    width: '300px',
    padding: '16px 18px 24px 16px',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: '6px',
    marginRight: '28px',
    marginBottom: '32px',
    '&:last-child': {
      marginRight: 0,
    },
  },
  photo: {
    borderRadius: '6px',
    marginBottom: '25px',
    backgroundColor: '#232323'
  },
  info: {
    position: 'relative',
  },
  name: {
    fontWeight: 600,
    fontSize: 24,
  },
  link: {
    position: 'absolute',
    top: '5px',
    right: 0,
  },
  line: {
    height: '8px',
    borderRadius: '6px',
    background: 'linear-gradient(269.9deg, #3C39FB 1.82%, #66A5FE 35.06%, #24D1F3 53.98%, #27EDBE 72.9%, #2AFD9B 100%)',
    margin: '16px 0',
  },
  profession: {
    fontSize: '15px',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
