import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    background: 'linear-gradient(180deg, #16141D 0%, #100D17 100%)',
    borderRadius: '6px',
    zIndex: 1,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: '26px 24px 29px 24px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '26px 34px 29px 29px',
    },
  },
  paperHead: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingBottom: theme.spacing(3.75),
    '&> img': {
      marginRight: 16,
    },
    '&> h3': {
      color: '#fff',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      display: 'block',
      width: '100%',
      height: '4px',
      borderRadius: '10px',
    },
    '&.default:after': {
      backgroundImage: 'linear-gradient(90deg, #FD9885 0%, #F35645 51.04%, #EB271F 100%)',
    },
    '&.primary:after': {
      backgroundImage:
        'linear-gradient(269.9deg, #3C39FB 1.82%, #66A5FE 35.06%, #24D1F3 53.98%, #27EDBE 72.9%, #2AFD9B 100%)',
    },
  },
  chips: {
    background: 'linear-gradient(270deg, #2E2C35 0%, #47464D 100%)',
    color: '#fff',
    fontWeight: 600,
    '&>span': {
      textOverflow: 'inherit',
      paddingLeft: 10,
      paddingRight: 10,
    },
    [theme.breakpoints.down('md')]: {
      width: 60,
    },
    [theme.breakpoints.up('lg')]: {
      width: 105,
    },
  },

  table: {
    '& .MuiTableCell-body, .MuiTableCell-head  ': {
      paddingLeft: '0px',
    },
    '& .MuiTableCell-head': {
      paddingTop: '10px',
    },
    '& .MuiTableBody-root': {
      '& > .MuiTableRow-root': {
        '&:first-child > .MuiTableCell-root ': {
          paddingTop: '32px',
        },
        '&:last-child > .MuiTableCell-root ': {
          paddingBottom: '0px',
        },
      },
    },
  },
  tableCell: {
    border: 'none',
    color: '#ffffff99',
    fontSize: '22px',
    padding: '44px 24px 44px 16px',
  },
  tableCellHead: {
    border: 'none',
    color: '#fff',
    fontSize: '20px',
  },
}));

export default useStyles;
