import React, {FC} from 'react';

import Container from "../../../../../../components/Container";
import useStyles from "../styles";

const Texture: FC = () => {
  const classes = useStyles();

  return (
    <Container variant="large">
      <div className={classes.section}>
        <div className={classes.title}>
          <div className="orangePurple">Texture Codec</div>
        </div>
        <div className="rows">
          <div className="row row-1">
            <div className="caption border">
              Concept DEVELOPMENT
            </div>
          </div>
          <div className="row row-2">
            <span className="mini-caption greyBg">Q2</span>
            <div className="text">Launch of development</div>
          </div>
          <div className="row row-3">
            <span className="mini-caption greyBg mr4">Q2</span>
            <div className="text">Presentation of a working prototype</div>
          </div>
          <div className="row row-4">
            <span className="mini-caption greyBg mr4">Q3</span>
            <div className="text">Preparation for the commercial launch</div>
          </div>
          <div className="row row-5">
            <span className="mini-caption orangePurpleBg">$$$</span>
            <div className="text">First revenue</div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Texture;
