/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

interface ButtonProps {
  children: any,
  variant: string
}
const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'block',
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    '&.default': {
      maxWidth: '1134px',
    },
    '&.large': {
      maxWidth: '1350px',
    },

  },
}));

const Container: FC<ButtonProps> = ({
  children,
  variant,
}) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.container, variant)}>
      {children}
    </Box>
  );
};

export default Container;
