import { store } from 'react-notifications-component';

type NotificationTitleType = 'success' | 'danger' | 'info' | 'default' | 'warning';
type GetNotificationTitleType = 'Success' | 'Error' | 'Warning' | undefined;

export const SUCCESS = 'success';
export const ERROR = 'danger';
export const INFO = 'info';
export const DEFAULT = 'default';
export const WARNING = 'warning';
const types = [SUCCESS, ERROR, WARNING, DEFAULT, INFO];

export function getNotificationTitle(type: NotificationTitleType): GetNotificationTitleType {
  switch (type) {
    case 'success':
      return 'Success';
    case 'danger':
      return 'Error';
    case 'warning':
      return 'Warning';
    case 'info':
      return undefined;
    case 'default':
      return undefined;
    default:
      return undefined;
  }
}

export const showNotification = (
  type: NotificationTitleType = DEFAULT,
  message: string,
  duration = 5000,
): void => {
  if (types.indexOf(type) === -1) type = DEFAULT;
  const notification = {
    type,
    title: getNotificationTitle(type),
    message,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration,
      onScreen: true,
      pauseOnHover: true,
      waitForAnimation: false,
      showIcon: true,
      click: true,
      touch: true,
    },
  };
  store.addNotification(notification);
};
