/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react/jsx-key */
import React from 'react';
import styled from 'styled-components';
import useStyles from './styles';
import Table from './table/Table';
import { COLUMNS1 } from './table/columns/columnsTable1';
import { COLUMNS2 } from './table/columns/columnsTable2';
import { COLUMNS_FOOTER1 } from './table/columns/columnsFooter1';
import { COLUMNS_FOOTER2 } from './table/columns/columnsFooter2';

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      .chips{
        background: linear-gradient(270deg, #2E2C35 0%, #47464D 100%);
        color: #fff;
        font-weight: 600;
        width: 100%;
        span: {
          text-overflow: inherit;
          padding-left: 10;
          padding-right: 10;
        }
        &.primary{
          background:
           linear-gradient(269.9deg,
             #3C39FB 1.82%, #66A5FE 35.06%, #24D1F3 53.98%, #27EDBE 72.9%, #2AFD9B 100%);
        }
      }
    }

    th{
      width: 182px;
    }
    .row1_0{
      padding-left: 10px;
    }

    th:first-child,
    td:first-child{
      min-width: 150px;
      text-align:left;
      font-family: 'TT Hoves';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 150%;
    }
    th:first-child{
      font-family: 'TT Hoves';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 150%;
    }

    th,
    td {
      background: none !important;
      color: #ffffffa6 !important;
      margin: 0;
      border: none;
      font-family: 'TT Hoves';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 150%;
      .primary-color{
        background: linear-gradient(
          269.9deg, #3C39FB 1.82%, #66A5FE 35.06%, #24D1F3 53.98%, #27EDBE 72.9%, #2AFD9B 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      :last-child {
        border-right: 0;
      }
    },
  }
`;

function ProductionTable({ tableData, tableIndex }): JSX.Element {
  const classes = useStyles();

  const data = React.useMemo(() => tableData, [tableIndex, tableData]);

  const columns1 = React.useMemo(() => COLUMNS1, []);
  const columns2 = React.useMemo(() => COLUMNS2, []);
  const columnsFooter1 = React.useMemo(() => COLUMNS_FOOTER1, []);
  const columnsFooter2 = React.useMemo(() => COLUMNS_FOOTER2, []);

  const selectColumn = tableIndex === 0 ? columns1 : columns2;
  const selectColumnFooter = tableIndex === 0 ? columnsFooter1 : columnsFooter2;

  return (
    <Styles>
      <Table columns={selectColumn} data={data} />
      <div className={`${classes.paperHead} primary`} />
      <Table columns={selectColumnFooter} data={[]} />
    </Styles>
  );
}

export default ProductionTable;
