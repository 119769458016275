import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	footer: {
    paddingBottom: 80,
		[theme.breakpoints.between('xs', 'sm')]: {
			paddingTop: 160,
		},
		[theme.breakpoints.only('md')]: {
			paddingTop: 261,
		},
		[theme.breakpoints.up('lg')]: {
			paddingTop: 100,
		},
	},
	caption: {
		[theme.breakpoints.only('md')]: {
			maxWidth: 288,
		},
		[theme.breakpoints.up('lg')]: {
			textAlign: 'center'
		},
	},
	content: {
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			justifyContent: 'space-around',
		},
	},
	contact: {
		flexShrink: 0,
		[theme.breakpoints.between('xs', 'sm')]: {
			marginBottom: theme.spacing(7.5)
		},
		[theme.breakpoints.only('md')]: {
			width: 288,
		},
		[theme.breakpoints.only('lg')]: {
			width: 465,
      '& h1': {
			  fontSize: 85
      }
		},
		[theme.breakpoints.up('xl')]: {
			width: 575,
      '& h1': {
        fontSize: 100
      },
      '& form': {
			  paddingRight: '50px'
      }
		},
		'& .MuiButton-root': {
			[theme.breakpoints.down('md')]: {
				width: "100%"
			},
			[theme.breakpoints.up('lg')]: {
				width: 169
			},
		},
    '& h1': {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
        fontSize: 40,
        lineHeight: 1.1,
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: 1014,
        marginBottom: theme.spacing(10)
      },
    },
	},
	details: {
		flexShrink: 0,
		[theme.breakpoints.only('md')]: {
			width: 288,
		},
		[theme.breakpoints.only('lg')]: {
			width: 382,
		},
		[theme.breakpoints.up('lg')]: {
			paddingTop: theme.spacing(2.8)
		},
		[theme.breakpoints.up('xl')]: {
			width: 410,
		},
		'& .MuiButton-root': {
			[theme.breakpoints.down('md')]: {
				width: "100%"
			},
			[theme.breakpoints.up('lg')]: {
				width: 275
			},
		},
		'& h3': {
			[theme.breakpoints.between('xs', 'sm')]: {
				marginBottom: theme.spacing(5)
			},
			[theme.breakpoints.only('md')]: {
				lineHeight: 1.2,
        marginBottom: '28px',
      },
			[theme.breakpoints.up('lg')]: {
				marginBottom: '28px',
        lineHeight: 1.2,
      },
		},
	},
	btnDown: {
		margin: '40px 0',
		"&:hover": {
			backgroundColor: "transparent",
			'& .MuiButton-startIcon': {
				border: '1px solid #29F4B2',
			}
		},
		'& .MuiButton-startIcon': {
			border: '1px solid #29f4b233',
			borderRadius: '50%',
			fontSize: '24px',
			width: 40,
			height: 40,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			transition: "0.4s",
			marginRight: 10
		},
	},
}));

export default useStyles;
